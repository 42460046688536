import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
  Tooltip,
  Tabs,
  Modal,
  AutoComplete,
  Collapse,
  Radio,
  Col,
  Row,
  Card,
  Select,
  Space,
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Alert,
  Checkbox,
  message,
  Carousel
} from 'antd';

function AwwexLandingPageBodyTop() {

  const [isHoveredFedex, setIsHoveredFedex] = useState(false);
  const fedeximageLinkStyle = {
    display: "block",
    width: "252px",
    height: "120px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredFedex
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_03.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_03.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredEtsy, setIsHoveredEtsy] = useState(false);
  const etsyimageLinkStyle = {
    display: "block",
    width: "283px",
    height: "120px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredEtsy
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_05.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_05.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredPtt, setIsHoveredPtt] = useState(false);
  const pttimageLinkStyle = {
    display: "block",
    width: "276px",
    height: "120px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredPtt
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_07.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_07.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredAmazon, setIsHoveredAmazon] = useState(false);
  const amazonimageLinkStyle = {
    display: "block",
    width: "276px",
    height: "120px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredAmazon
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_09.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_09.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredTcimax, setIsHoveredTcimax] = useState(false);
  const tcimaximageLinkStyle = {
    display: "block",
    width: "272px",
    height: "120px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredTcimax
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_11.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_11.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredIdeasoft, setIsHoveredIdeasoft] = useState(false);
  const ideasoftimageLinkStyle = {
    display: "block",
    width: "263px",
    height: "120px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredIdeasoft
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_13.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_13.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredEbay, setIsHoveredEbay] = useState(false);
  const ebayimageLinkStyle = {
    display: "block",
    width: "249px",
    height: "124px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredEbay
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_22.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_22.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredWalmart, setIsHoveredWallmart] = useState(false);
  const wallmartimageLinkStyle = {
    display: "block",
    width: "283px",
    height: "124px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredWalmart
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_23.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_23.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredTsoft, setIsHoveredTsoft] = useState(false);
  const tsoftimageLinkStyle = {
    display: "block",
    width: "276px",
    height: "124px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredTsoft
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_24.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_24.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredTNT, setIsHoveredTNT] = useState(false);
  const tntimageLinkStyle = {
    display: "block",
    width: "252px",
    height: "124px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredTNT
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_26.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_26.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredParasut, setIsHoveredParasut] = useState(false);
  const parasutimageLinkStyle = {
    display: "block",
    width: "272px",
    height: "124px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredParasut
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_28.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_28.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredDhl, setIsHoveredDhl] = useState(false);
  const dhlimageLinkStyle = {
    display: "block",
    width: "263px",
    height: "124px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredDhl
      ? "url('https://www.awwex.com/assets/images/afirmalar2/images/AwwEx-Site-Giydirme3_bodytop_29.png')"
      : "url('https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_29.png')", // Hover durumuna göre resim değişir
  };


  return (
    <div className="AppBodyTop">
      <table id="Table_01" height="361" border="0" cellPadding="0" cellSpacing="0">
        <tr>
          <td colSpan="16">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_01.png" width="1920" height="43" alt=""/>
          </td>
        </tr>
        <tr>
          <td rowSpan="4">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_02.png" width="115" height="318" alt=""/></td>
          <td colSpan="2">
            <a
              href="https://www.awwex.com/"
              style={fedeximageLinkStyle}
              onMouseEnter={() => setIsHoveredFedex(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredFedex(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td rowSpan="4">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_04.png" width="19" height="318" alt=""/></td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={etsyimageLinkStyle}
              onMouseEnter={() => setIsHoveredEtsy(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredEtsy(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td rowSpan="4">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_06.png" width="15" height="318" alt=""/></td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={pttimageLinkStyle}
              onMouseEnter={() => setIsHoveredPtt(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredPtt(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td rowSpan="4">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_08.png" width="14" height="318" alt=""/></td>
          <td colSpan="3">
            <a
              href="https://www.awwex.com/"
              style={amazonimageLinkStyle}
              onMouseEnter={() => setIsHoveredAmazon(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredAmazon(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td rowSpan="4">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_10.png" width="23" height="318" alt=""/></td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={tcimaximageLinkStyle}
              onMouseEnter={() => setIsHoveredTcimax(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredTcimax(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td rowSpan="4">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_12.png" width="17" height="318" alt=""/>
          </td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={ideasoftimageLinkStyle}
              onMouseEnter={() => setIsHoveredIdeasoft(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredIdeasoft(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td rowSpan="4">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_14.png" width="117" height="318" alt=""/></td>
        </tr>
        <tr>
          <td colSpan="2">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_15.png" width="252" height="20" alt=""/></td>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_16.png" width="283" height="20" alt=""/></td>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_17.png" width="276" height="20" alt=""/></td>
          <td colSpan="3">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_18.png" width="254" height="20" alt=""/></td>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_19.png" width="272" height="20" alt=""/></td>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_20.png" width="263" height="20" alt=""/></td>
        </tr>
        <tr>
          <td rowSpan="2">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_21.png" width="3" height="178" alt=""/>
          </td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={ebayimageLinkStyle}
              onMouseEnter={() => setIsHoveredEbay(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredEbay(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={wallmartimageLinkStyle}
              onMouseEnter={() => setIsHoveredWallmart(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredWallmart(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={tsoftimageLinkStyle}
              onMouseEnter={() => setIsHoveredTsoft(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredTsoft(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td rowSpan="2">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_25.png" width="1" height="178" alt=""/></td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={tntimageLinkStyle}
              onMouseEnter={() => setIsHoveredTNT(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredTNT(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>

          <td rowSpan="2">
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_27.png" width="1" height="178" alt=""/></td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={parasutimageLinkStyle}
              onMouseEnter={() => setIsHoveredParasut(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredParasut(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
          <td>
            <a
              href="https://www.awwex.com/"
              style={dhlimageLinkStyle}
              onMouseEnter={() => setIsHoveredDhl(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredDhl(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_30.png" width="249" height="54" alt=""/></td>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_31.png" width="283" height="54" alt=""/></td>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_32.png" width="276" height="54" alt=""/></td>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_33.png" width="252" height="54" alt=""/></td>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_34.png" width="272" height="54" alt=""/></td>
          <td>
            <img src="https://www.awwex.com/assets/images/afirmalar1/images/AwwEx-Site-Giydirme2_35.png" width="263" height="54" alt=""/></td>
        </tr>
      </table>
    </div>
  )
}

export default AwwexLandingPageBodyTop;
