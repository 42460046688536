import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";


const PricingApps = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/pricingget`}/>
  
    <Route path={`${match.url}/pricingget`} component={asyncComponent(() => import('./pricingget.js'))}/>
 </Switch>
);

export default PricingApps;
