import axiosInstance from './dlaxios';

export const getAPICall = async (url) => {
    return axiosInstance.get(url);
}

export const postAPICall = (url, data) => {
    return axiosInstance.post(url, data, {timeout: 60000} );
}

export const postAPICallTypeArrayBuffer = (url, data) => {
    return axiosInstance.post(url, data, {timeout: 60000, responseType: 'arraybuffer'});
}

export const putAPICall = (url, data) => {
    return axiosInstance.put(url, data);
}
export const deleteAPICall = (url) => {
    return axiosInstance.delete(url);
}
