import React, {useRef, useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {
  Tooltip,
  Tabs,
  Modal,
  AutoComplete,
  Collapse,
  Radio,
  Col,
  Row,
  Card,
  Select,
  Space,
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Alert,
  Checkbox,
  message,
  Carousel
} from 'antd';
import PricingGet from "../routes/pricing/pricingget";

function AwwexLandingPageFooter(props) {

  const [about, setAbout] = useState(false);
  const aboutLinkStyle = {
    display: "block",
    width: "268px",
    height: "81px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: about
      ? "url('https://www.awwex.com/assets/images/landingfooter/images/footer_05_2.png')"
      : "url('https://www.awwex.com/assets/images/landingfooter/images/footer_05.png')", // Hover durumuna göre resim değişir
  };
  const [awwexMain, setAwwexMain] = useState(false);
  const awwexMainLinkStyle = {
    display: "block",
    width: "360px",
    height: "127px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: awwexMain
      ? "url('https://www.awwex.com/assets/images/landingfooter/images/footer_03_2.png')"
      : "url('https://www.awwex.com/assets/images/landingfooter/images/footer_03.png')", // Hover durumuna göre resim değişir
  };
  const [awwexprivileges, setAwwexprivileges] = useState(false);
  const awwexPrivilegesLinkStyle = {
    display: "block",
    width: "377px",
    height: "64px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    marginTop: "-37px",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: awwexprivileges
      ? "url('https://www.awwex.com/assets/images/landingfooter/images/footer_16_2.png')"
      : "url('https://www.awwex.com/assets/images/landingfooter/images/footer_16.png')", // Hover durumuna göre resim değişir
  };

  const [awwexsss, setAwwexsss] = useState(false);
  const awwexSSSLinkStyle = {
    display: "block",
    width: "439",
    height: "62px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    marginTop: "-37px",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: awwexsss
      ? "url('https://www.awwex.com/assets/images/landingfooter/images/footer_18_2.png')"
      : "url('https://www.awwex.com/assets/images/landingfooter/images/footer_18.png')", // Hover durumuna göre resim değişir
  };

  const [awwexgli, setAwwexgli] = useState(false);
  const awwexGLILinkStyle = {
    display: "block",
    width: "377px",
    height: "75px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    marginTop: "-74px",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: awwexgli
      ? "url('https://www.awwex.com/assets/images/landingfooter/images/footer_19_2.png')"
      : "url('https://www.awwex.com/assets/images/landingfooter/images/footer_19.png')", // Hover durumuna göre resim değişir
  };

  const [awwexKullanim1, setAwwexKullanim1] = useState(false);
  const awwexKullanim1LinkStyle = {
    display: "block",
    width: "439px",
    height: "62px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: awwexKullanim1
      ? "url('https://www.awwex.com/assets/images/landingfooter/images/footer_12_2.png')"
      : "url('https://www.awwex.com/assets/images/landingfooter/images/footer_12.png')", // Hover durumuna göre resim değişir
  };

  const [awwexSupport, setAwwexSupport] = useState(false);
  const awwexSupportLinkStyle = {
    display: "block",
    width: "260px",
    height: "81px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: awwexSupport
      ? "url('https://www.awwex.com/assets/images/landingfooter/images/footer_07_2.png')"
      : "url('https://www.awwex.com/assets/images/landingfooter/images/footer_07.png')", // Hover durumuna göre resim değişir
  };

  const [awwexAWWEX, setAwwexAWWEX] = useState(false);
  const awwexAWWEXLinkStyle = {
    display: "block",
    width: "377px",
    height: "50px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    marginTop: "-26px",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: awwexAWWEX
      ? "url('https://www.awwex.com/assets/images/landingfooter/images/footer_14_2.png')"
      : "url('https://www.awwex.com/assets/images/landingfooter/images/footer_14.png')", // Hover durumuna göre resim değişir
  };

  const [awwexMail, setAwwexMail] = useState(false);
  const awwexMailLinkStyle = {
    display: "block",
    width: "360px",
    height: "76px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    marginTop: "-68px",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: awwexMail
      ? "url('https://www.awwex.com/assets/images/landingfooter/images/footer_17_2.png')"
      : "url('https://www.awwex.com/assets/images/landingfooter/images/footer_17.png')", // Hover durumuna göre resim değişir
  };


  function awwexKullanimvideo1() {
    showModal();
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const styles = {
    container: {
      position: "relative",
      width: "100%",
      height: "100%",
      margin: "0 auto",
    },
    video: {
      width: "100%",
      height: "100%",
      display: "block",
    },
    overlayheader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // %50 siyah transparan
      display: "flex",
      justifyContent: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // %50 siyah transparan
      display: "none",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      color: "#fff",
      textAlign: "center",
      padding: "20px",
    },
    title: {
      fontSize: "24px",
      marginBottom: "20px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    input: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      outline: "none",
      width: "200px",
    },
    button: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      backgroundColor: "#1677ff",
      color: "#fff",
      border: "none",
      cursor: "pointer",
    }
  };

  return (
    <Form component={false}>
      <div className="AppBodyFooter" style={{
        marginTop: "0", paddingTop: "0", backgroundColor: "black",
        backgroundImage: "url('https://www.awwex.com/assets/images/landingfooter/footer2.png')"
      }}>
        <table id="Table_01" width="100%" height="100%" border="0" cellPadding="0" cellSpacing="0">
          <tr>
            <td style={{marginTop: "147px"}} colSpan="11">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_01.png" width="1920" height="93" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="93" alt=""/></td>
          </tr>
          <tr>
            <td rowSpan="14">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_02.png" style={{marginTop: "-47px"}} width="80" height="725" alt=""/></td>
            <td rowSpan="2">
              <a
                href="#"
                onClick={() => window.location.reload()}
                style={awwexMainLinkStyle}
                onMouseEnter={() => setAwwexMain(true)} // Hover durumu aktif
                onMouseLeave={() => setAwwexMain(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td colSpan="2" rowSpan="9">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_04.png" style={{marginTop: "-42px"}} width="376" height="291" alt=""/></td>
            <td>
              <a
                href="#"
                onClick={() => props.masterFunction("about")}
                style={aboutLinkStyle}
                onMouseEnter={() => setAbout(true)} // Hover durumu aktif
                onMouseLeave={() => setAbout(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td colSpan="3" rowSpan="4">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_06.png" style={{marginTop: "-13px"}} width="242" height="158" alt=""/></td>
            <td>
              <a
                href="#"
                onClick={() => props.masterFunction("landingsss")}
                style={awwexSupportLinkStyle}
                onMouseEnter={() => setAwwexSupport(true)} // Hover durumu aktif
                onMouseLeave={() => setAwwexSupport(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td colSpan="2" rowSpan="3">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_08.png" style={{marginTop: "-1px"}} width="334" height="153" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="81" alt=""/></td>
          </tr>
          <tr>
            <td rowSpan="3">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_09.png" style={{marginTop: "-14px"}} width="268" height="77" alt=""/></td>
            <td rowSpan="2">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_10.png" width="260" height="72" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="46" alt=""/></td>
          </tr>
          <tr>
            <td rowSpan="4">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_11.png" style={{marginTop: "-24px"}} width="360" height="88" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="26" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="2" rowSpan="3">
              <a
                href="#"
                onClick={() => awwexKullanimvideo1()}
                style={awwexKullanim1LinkStyle}
                onMouseEnter={() => setAwwexKullanim1(true)} // Hover durumu aktif
                onMouseLeave={() => setAwwexKullanim1(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td rowSpan="11">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_13.png" style={{marginTop: "-48px"}} width="155" height="572" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="5" alt=""/></td>
          </tr>
          <tr>

            <td colSpan="2">
              <a
                href="#"
                onClick={() => window.open('https://www.assetgli.com/worldwideexpress-anasayfa', '_blank')}
                style={awwexAWWEXLinkStyle}
                onMouseEnter={() => setAwwexAWWEX(true)} // Hover durumu aktif
                onMouseLeave={() => setAwwexAWWEX(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td colSpan="2" rowSpan="8">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_15.png" width="133" height="316" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="50" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="2" rowSpan="2">
              <a
                href="#"
                onClick={() => props.masterFunction("privileges")}
                style={awwexPrivilegesLinkStyle}
                onMouseEnter={() => setAwwexprivileges(true)} // Hover durumu aktif
                onMouseLeave={() => setAwwexprivileges(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="7" alt=""/></td>
          </tr>
          <tr>

            <td rowSpan="3">
              <a
                href="#"
                onClick={() => window.open('mailto:info@awwex.com', '_self')}
                style={awwexMailLinkStyle}
                onMouseEnter={() => setAwwexMail(true)} // Hover durumu aktif
                onMouseLeave={() => setAwwexMail(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td colSpan="2" rowSpan="2">
              <a
                href="#"
                onClick={() => props.masterFunction("landingsss")}
                style={awwexSSSLinkStyle}
                onMouseEnter={() => setAwwexsss(true)} // Hover durumu aktif
                onMouseLeave={() => setAwwexsss(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="57" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="2" rowSpan="4">
              <a
                href="#"
                onClick={() => window.open('https://www.assetgli.com/', '_blank')}
                style={awwexGLILinkStyle}
                onMouseEnter={() => setAwwexgli(true)} // Hover durumu aktif
                onMouseLeave={() => setAwwexgli(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="5" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="2" rowSpan="2">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_20.png" width="439" height="58" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="14" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="3" rowSpan="3">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_21.png" style={{marginTop: "-89px"}} width="736" height="183" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="44" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="2" rowSpan="4">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_22.png" style={{marginTop: "-90px"}} width="439" height="390" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="12" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="2">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_23.png" style={{marginTop: "-96px"}} width="377" height="127" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="127" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="2" rowSpan="2">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_24.png" style={{marginTop: "-96px"}} width="560" height="251" alt=""/></td>
            <td colSpan="4">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_25.png" style={{marginTop: "-96px"}} width="650" height="137" alt=""/></td>
            <td rowSpan="2">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_26.png" style={{marginTop: "-96px"}} width="36" height="251" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="137" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="4">
              <img src="https://www.awwex.com/assets/images/landingfooter/images/footer_27.png" style={{marginTop: "-96px"}} width="650" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="1" height="1" alt=""/></td>
          </tr>
          <tr>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="80" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="360" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="200" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="176" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="268" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="109" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="97" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="36" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="260" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="179" height="1" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingfooter/images/spacer.gif" width="155" height="1" alt=""/></td>
            <td></td>
          </tr>
        </table>
      </div>
      <div>
        <Modal title="Kullanım" width={600} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/iDr52BitSDo?autoplay=1&loop=1&mute=1&playlist=iDr52BitSDo"
            title="YouTube video player"
            frameBorder="0"
            allow="autoplay; encrypted-media; loop"
            allowFullScreen
          ></iframe>
        </Modal>
      </div>
    </Form>
  )
}

export default AwwexLandingPageFooter;
