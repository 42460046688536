import React, {useRef, useState, useEffect, useCallback} from "react";
import {Form, Card, Col, Input, Row, Steps, Table, message, Radio, Space} from "antd";
import { GoPackage, GoPackageDependents } from "react-icons/go";
import { LiaShippingFastSolid } from "react-icons/lia";
import { LuPackageSearch, LuPackageCheck } from "react-icons/lu";
import { CallerPullShipmentTrackingStatusCommand } from "../../datalayer/tracking/callertrackingcontroller";

export default function Tracking(props) {
  const [form] = Form.useForm();
  const { Search } = Input;
  const [reference, setReference] = useState("");
  const [data, setData] = useState({
    shipmentTrackingStatusResults: {
      estimatedDeliveryTime: null,
      trackingReference: "",
      trackingInformations: [],
    },
    receiverCountryName: "",
  });
  const [selectedPieceTrackingReference, setSelectedPieceTrackingReference] = useState("");

  useEffect(async () => {
    localStorage.setItem("pageName", "Gönderi Takibi");

    let externalPageRequest = {};
    try {
      externalPageRequest =
          props.location.pathname.split("/")[2] != "tracking"
              ? JSON.parse(props.location.pathname.split("/")[2])
              : null;
      if (externalPageRequest) {
        setReference(externalPageRequest.assetreference);
      }
    } catch (error) {
      console.error("externalPageRequest error", error);
    }
  }, []);

  useEffect( async () => {
    localStorage.setItem("pageName", "Gönderi Takibi");
    debugger;
    try {
      if (props.externalPageRequest){
        setReference(props.externalPageRequest.assetreference);
      }
    } catch (error) {
      console.log('externalPageRequest error',error)
    }
  },[props.externalPageRequest]);

  useEffect(() => {
    if (reference) {
      form.setFieldsValue({
        assetReference: reference
      });
      form.submit();
    }
  }, [reference]);

  useEffect(() => {
    if (data.shipmentTrackingStatusResults.length > 0) {
      setSelectedPieceTrackingReference(data.shipmentTrackingStatusResults[0].trackingReference);
    }
  }, [data]);

  const onFinish = async (values) => {
    const response = await CallerPullShipmentTrackingStatusCommand(values);
    if (response?.status === 200) {
      response.data.shipmentTrackingStatusResults.forEach((result, index) => {
        result.trackingInformations.forEach((trackingInformation, trackingIndex) => {
          trackingInformation.key = index + "-" + trackingIndex;
        });
      });
      setData(response.data);
    } else {
      setData([]);
      message.error(response.ReturnText);
    }
  };

  const handleTrackingReferenceRadioChange = useCallback((e) => {
    setSelectedPieceTrackingReference(e.target.value);
  }, []);

  const columns = [
    {
      title: "Tarih",
      dataIndex: "date",
      render: (value) => (value ? new Date(value).toLocaleString() : ""),
    },
    {
      title: "Konum",
      dataIndex: "location",
    },
    {
      title: "Durum",
      dataIndex: "description",
    },
  ];

  return (
    <>
      <Card className="gx-card">
        <Row>
          <Form name="tracking" form={form} onFinish={onFinish}>
            <Col span={24}>
              <Form.Item name="assetReference">
                <Search
                  placeholder="Referans"
                  onSearch={(value) => {
                    form.submit();
                  }}
                  enterButton
                  style={{ width: 300 }}
                />
              </Form.Item>
            </Col>
          </Form>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 20 }}>
                <strong style={{ fontWeight: "bold" }}>Alıcı Ülke:</strong>{" "}
                {data?.receiverCountryName}
              </div>
              {data?.shipmentTrackingStatusResults?.estimatedDeliveryTime !=
                null && (
                <div>
                  <strong style={{ fontWeight: "bold" }}>
                    Tahmini Teslim Tarihi:
                  </strong>{" "}
                  {new Date(
                    data?.shipmentTrackingStatusResults?.estimatedDeliveryTime
                  ).toLocaleString()}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 20, marginBottom: 20 }}>
          <Col span={24}>
            <Steps
              items={[
                {
                  title: "Sipariş Alındı",
                  status: "finish",
                  icon: <GoPackage />,
                },
                {
                  title: "İşlemde",
                  status: "finish",
                  icon: <LuPackageSearch />,
                },
                {
                  title: "Sevk Ediliyor",
                  status: "process",
                  icon: <LiaShippingFastSolid />,
                },
                {
                  title: "Dağıtımda",
                  status: "wait",
                  icon: <GoPackageDependents />,
                },
                {
                  title: "Teslim Edildi",
                  status: "wait",
                  icon: <LuPackageCheck />,
                },
              ]}
            ></Steps>
          </Col>
        </Row>
        {data?.shipmentTrackingStatusResults && data.shipmentTrackingStatusResults.length > 1 &&
          <Row>
            <Col span={24}>
              {
                <>
                  <Radio.Group
                      value={selectedPieceTrackingReference}
                      style={{marginBottom: 20}}
                      onChange={handleTrackingReferenceRadioChange}
                  >
                    <Space direction="vertical">
                      {data.shipmentTrackingStatusResults.map((result, i) => (
                          <Radio
                              key={result.trackingReference}
                              value={result.trackingReference}
                          >
                            Parça {i + 1}: {result.trackingReference}
                          </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </>
              }
            </Col>
          </Row>
        }
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={Array.isArray(data?.shipmentTrackingStatusResults)
                  ? data.shipmentTrackingStatusResults
                      .filter(r => r.trackingReference === selectedPieceTrackingReference)
                      .flatMap(r => r.trackingInformations || [])
                  : []}
              rowKey="key"
            ></Table>
          </Col>
        </Row>
      </Card>
    </>
  );
}
