import React, {memo, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {ConfigProvider} from 'antd';
import {IntlProvider} from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import {setInitUrl, refreshToken} from "appRedux/actions/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL, THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import AwwexLandingPageHeader from "../../awwexlangingpagereact/awwexlangingpageindexHeader";
import AwwexLandingPageBodyTop from "../../awwexlangingpagereact/ajaxlandingpageindexbodytop";
import AwwexLandingPageBodyMiddle from "../../awwexlangingpagereact/awwexlandingpageindexbodymiddle";
import Awwexlandinghumans from "../../awwexlangingpagereact/awwexlandinghumans";
import Awwexlandingfooter from "../../awwexlangingpagereact/awwexlandingfooter";
import AwwexlangingpageindexHakkimizda from "../../awwexlangingpagereact/awwexlangingpageindexHakkimizda";
import AwwexlangingpageindexAyricaliklar from "../../awwexlangingpagereact/awwexlangingpageindexAyricaliklar";
import Awwexlangingpageindexsss from "../../awwexlangingpagereact/awwexlangingpageindexsss";
const RestrictedRoute = ({component: Component, location, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: location}
          }}
        />}
  />;

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};

let styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

const App = () => {
  const locale = useSelector(({settings}) => settings.locale);
  const navStyle = useSelector(({settings}) => settings.navStyle);
  const layoutType = useSelector(({settings}) => settings.layoutType);
  const themeColor = useSelector(({settings}) => settings.themeColor);
  const themeType = useSelector(({settings}) => settings.themeType);
  const isDirectionRTL = useSelector(({settings}) => settings.isDirectionRTL);

  const dispatch = useDispatch();
  const {authUser, initURL} = useSelector(({auth}) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl');
      document.documentElement.setAttribute('data-direction', 'rtl')
    } else {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr')
    }

    if (themeColor) {
      styleSheetLink.href = `/css/${themeColor}.min.css`;
    }
  }, [themeColor, isDirectionRTL]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
      styleSheetLink.href = "/css/dark_theme.min.css";
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      styleSheetLink.href = "";
    }
  }, [themeType]);

  useEffect(() => {
    if (locale)
      document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
  }, [location.search, dispatch, initURL, location.pathname]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  useEffect(() => {



   if (authUser !== null) {
    if(localStorage.getItem("myuid")!==undefined && localStorage.getItem("myuid")!=="")
    {
      let rtrequest = {};//
      rtrequest.UserID = localStorage.getItem("myuid");
      dispatch(refreshToken(rtrequest))
    }
   }
   console.log("authUser", authUser)

    //alert(location.pathname);
    if (location.pathname === '/') {
      if (authUser === null) {
        //history.push('/signin');
        //history.push('/lpfeawwx/index.html')
        console.log("authuser - null")
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        //history.push('/main/dashboard/crypto');
        history.push('/shipment/shipmentlist');
      } else {
        history.push(initURL);
      }
    }
    if (location.pathname === '/lpfeawwx/index.html')
      {
          //window.location.replace("https://www.awwex.com/lpfeawwx/index.html")
          //window.location.replace("https://www.awwex.com/signin")
          console.log("lpfeawwx", location.pathname);
      }
  }, [authUser, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  const scrollContainerRef = useRef(null);

  // onWheel olayını işleyen fonksiyon
  const handleWheel = (event) => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollTop += event.deltaY; // DeltaY değerine göre yukarı/aşağı kaydır
    }
  };

  const [layer1isvisible, setLayer1isvisible] = useState(true);
  const [layer1_1isvisible, setLayer1_1isvisible] = useState(true);
  const [layer1_2isvisible, setLayer1_2isvisible] = useState(true);
  const [layer1_3isvisible, setLayer1_3isvisible] = useState(true);
  const [layer2isvisible, setLayer2isvisible] = useState(true);
  const [layer3isvisible, setLayer3isvisible] = useState(true);
  const [layer4isvisible, setLayer4isvisible] = useState(true);
  const [layer5isvisible, setLayer5isvisible] = useState(true);
  const [switchlayer, setSwitchlayer] = useState("");
  const topRef = useRef(null);
  function masterFunction(lswitchlayer)
  {
    lswitchlayer=(lswitchlayer===null||lswitchlayer===undefined||lswitchlayer==='')?"landingmaster":lswitchlayer;
    setSwitchlayer(lswitchlayer)

  }

  useEffect(()=>{
    switch(switchlayer)
    {
      case "about":
        setLayer5isvisible(false);
        setLayer4isvisible(false);
        setLayer3isvisible(false);
        setLayer2isvisible(false);
        setLayer1_2isvisible(false);
        setLayer1_3isvisible(false);
        setTimeout(()=>{setLayer1_1isvisible(true);},10)
        setLayer1isvisible(false);
        break;
      case "privileges":
        try {
          setLayer5isvisible(false);
          setLayer4isvisible(false);
          setLayer3isvisible(false);
          setLayer2isvisible(false);
          setLayer1_1isvisible(false);
          setLayer1_3isvisible(false);
          setTimeout(()=>{setLayer1_2isvisible(true);},10)
          setLayer1isvisible(false);
        }
        catch (ex)
        {

        }

        break;
      case "landingmaster":
        setLayer5isvisible(true);
        setLayer4isvisible(true);
        setLayer3isvisible(true);
        setLayer2isvisible(true);
        setLayer1_3isvisible(false);
        setLayer1_2isvisible(false);
        setLayer1_1isvisible(false);
        setLayer1isvisible(true);
        break;

      case "landingsss":
        setLayer5isvisible(false);
        setLayer4isvisible(false);
        setLayer3isvisible(false);
        setLayer2isvisible(false);
        setTimeout(()=>{setLayer1_3isvisible(true);},10)
        setLayer1_2isvisible(false);
        setLayer1_1isvisible(false);
        setLayer1isvisible(false);
        break;
    }
  },[switchlayer])

  useEffect(() => {
    masterFunction(switchlayer);
  }, []);

  return (
    <>
      {authUser===null&&location.pathname === '/'?(
        <div style={{backgroundColor: "white", height: '100%', clear: "both", overflowY: "auto", overflowX: "hidden", position: "relative"}}>
          <div style={{height: '80%', zIndex: 1, display: layer1isvisible ? "block" : "none"}}>
            <AwwexLandingPageHeader masterFunction={masterFunction}></AwwexLandingPageHeader>
          </div>
          <div style={{height: '80%', zIndex: 1, display: layer1_1isvisible ? "block" : "none"}}>
            <AwwexlangingpageindexHakkimizda masterFunction={masterFunction}></AwwexlangingpageindexHakkimizda>
          </div>
          <div style={{height: '80%', zIndex: 1, display: layer1_2isvisible ? "block" : "none"}}>
            <AwwexlangingpageindexAyricaliklar masterFunction={masterFunction}></AwwexlangingpageindexAyricaliklar>
          </div>
          <div style={{height: '80%', zIndex: 1, display: layer1_3isvisible ? "block" : "none"}}>
            <Awwexlangingpageindexsss masterFunction={masterFunction}></Awwexlangingpageindexsss>
          </div>
          <div style={{display: layer2isvisible ? "block" : "none", backgroundColor: "white", position: "relative", zIndex: 200, height: '35%', width: '100%', marginTop: "0px"}}>
            <AwwexLandingPageBodyTop></AwwexLandingPageBodyTop>
          </div>
          <div style={{display: layer3isvisible ? "block" : "none", backgroundColor: "white", position: "relative", zIndex: 200, height: '35%', width: '100%', marginTop: "0px"}}>
            <AwwexLandingPageBodyMiddle></AwwexLandingPageBodyMiddle>
          </div>
          <div style={{display: layer4isvisible ? "block" : "none", backgroundColor: "white", position: "relative", zIndex: 200, height: '180%', width: '100%', marginTop: "0px"}}>
            <Awwexlandinghumans masterFunction={masterFunction}></Awwexlandinghumans>
          </div>
          <div style={{
            display: layer5isvisible ? "block" : "none",
            backgroundColor: "white", position: "relative", zIndex: 200, height: '40%', width: '100%'
          }}>
            <Awwexlandingfooter masterFunction={masterFunction}></Awwexlandingfooter>
          </div>
        </div>
      ) : (
        <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>

        <Switch>
          <Route exact path='/signin' component={SignIn}/>
          <Route exact path='/signup' component={SignUp}/>
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location}
                           component={MainApp}/>
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )}
    </>
  )
};

export default memo(App);