import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../datalayer';

async function CallerRefreshTokenController(tokenreq){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/Token/RefreshToken', tokenreq);
            //console.log("CallerrefreshTokenController 1",response);
            return response;
        }
        catch(error){
            return error.response;
        }
}

export default CallerRefreshTokenController;