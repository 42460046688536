import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../datalayer';
import getMyMessage from "../../util/RestReturnCatchForException";

async function CallerPricingGetController(reqTempItem){
    try {
        const response = await postAPICall('/portalbend/Pricing/PricingGet', reqTempItem);
        return response.data;
    }
    catch(error){
        let jsonRtrn = {}
        try {
            jsonRtrn = getMyMessage(error);
        } catch (error) {
            jsonRtrn.ReturnCode = 500;
            jsonRtrn.ReturnText = error.response;
        }
        return jsonRtrn;
    }
}

export {CallerPricingGetController};