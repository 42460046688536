import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  REFRESH_TOKEN,
  SIGNOUT_USER,
  SIGNUP_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";
import CallerTokenController from '../../datalayer/login/calltokencontroller';
import CallerRefreshTokenController from '../../datalayer/login/callrefreshtokencontroller';
import { jwtDecode } from "jwt-decode";
import {
  CallerSendEmailForEmailConfirmationController,
  CallerCreateUserController,
  CallerGetUserWithPaginationController,
  CallerUpdatePasswordController
} from '../../datalayer/usercrud/callerusercontroller';
import {CallerCreateUserRoleGroupmapController, CallerGetUserRoleGroupmapsWithPaginationController} from '../../datalayer/usercrud/calleruserrolegroupcontroller';
import { CallerCreateOrUpdateCompanyEmployeesController, CallerCreateOrUpdateCompanyAddressController, CallerCreateOrUpdateAddressController, CallerCreateOrUpdateCompanyController, CallerGetCompanyListPaginationController, CallerGetCompanyAddressListPaginationController } from '../../datalayer/company/callercompanycreatecontroller';
import {getUrl} from '../../util/FrontendSpecs';

const updatepassword = async(values) => {
  let req = {
    Email: values.email,
    Password: values.password,
  }
  debugger;
  let CallerUpdatePassword = await CallerUpdatePasswordController(req);
  console.log("CallerUpdatePassword", CallerUpdatePassword);
  let rtrn = CallerUpdatePassword;
}
const createUser = async (values) => {
  console.log('createUser createUser:', values);

      let req = {
          Id: -1,
          Email: values.email,
          PhoneNumber: values.phone+'',
          UserName: values.name+" "+values.lastname,
          Password: values.password,
          Active: 1
      }

      console.log("save usercrud req", req);
      //row send
      let callerCreateUserController = await CallerCreateUserController(req);
      console.log("callerCreateUserController", callerCreateUserController);
      let rtrn = callerCreateUserController;

      debugger;

      if(rtrn.length>0)
      {

        let reqdrole = {
          Id: -1,
          RoleGroupID: 2,
          UserID: rtrn,
          Active: 1
        }


        let callerCreateUserRoleGroupmapController = await CallerCreateUserRoleGroupmapController(reqdrole);

        debugger;

        let request1 = {
          id: -1,
          name: "",
          companyTypeID:  1,
          taxID: "0",
          identificationNumber: 0,
          segmentID: 1,
          uID:'',
          cID:''
        }

        let rtrnn = await CallerCreateOrUpdateCompanyController(request1);
        debugger;
        if(rtrnn.length>0)
        {

          let request2 = {
            id: -1,
            addressName: '',
            address3: '',
            address4: '',
            countryCode: '',
            cityCode: '',
            stateeCode: '',
            zipCode:'',
            address1:'',
            address2:'',
            uID:'',
            cID:''
          }

          let rtrn2 = await CallerCreateOrUpdateAddressController(request2);
          debugger;
          if(rtrn2>0)
            {

              let request3 = {
                id: -1,
                companyID:rtrnn,
                addressID:rtrn2
              }
              let rtrn3 = await CallerCreateOrUpdateCompanyAddressController(request3);
              debugger;

              if(rtrn3>0)
                {

                  let request4= {
                    id: -1,
                    companyID:rtrnn,
                    userID:rtrn
                  }

                  let rtrn4 = await CallerCreateOrUpdateCompanyEmployeesController(request4);
                  debugger;


                  window.location.replace("https://www.awwex.com/tesekkurler");
                }

            }
        }
      }
};

const createUserWithEmailPasswordRequest = async (data) =>{

  debugger;

  if(data.rememberpassword)
    await updatepassword(data);
  else
    await createUser(data);

  debugger;
  let luser = await signInUserWithEmailPasswordRequest(data.email, data.password);

  localStorage.setItem('user',JSON.stringify(luser));

  return luser

  /*await  auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
    */
  }

function timeoutControl()
{

}


const signInUserWithEmailPasswordRequest = async (email, password) =>{

  localStorage.clear();

  let rqtoken = {
    "email": email,
    "password": password
  }
  let token = await CallerTokenController(rqtoken);

  if(token.data)
  if(token.data.ReturnCode !== 200)
  {
    token["message"] = token.data.ReturnText;
    return token;
  }

  localStorage.setItem('mypearl', token);

  const decoded = jwtDecode(token);
  let timeout=(decoded.exp * 1000) ;
  localStorage.setItem('myuid', decoded.nameid);
  localStorage.setItem('companyID', decoded.CompanyID);
  localStorage.setItem('companyName', decoded.CompanyName);
  localStorage.setItem("userName", decoded.UserName);
  localStorage.setItem("userEmail", decoded.Email);
  localStorage.setItem("timeout", timeout);

  setInterval(() => {
    let timeout = localStorage.getItem('timeout');

    localStorage.setItem("now", new Date().getTime());
    if(new Date().getTime()>timeout)
    {
      localStorage.clear();
      window.location.href = "/";
    }

  }, 2000);

  //console.log("decoded", decoded);

  let luser ={user:{id: decoded.nameid, name: decoded.unique_name, image:"https://via.placeholder.com/150", uid:decoded.nameid}}

  localStorage.setItem('user',JSON.stringify(luser));

  return luser
  /*
  await  auth.signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
    */
  }

  const signInUserWithEmailRefreshTokenRequest = async (UserID) =>{

    let timeout = localStorage.getItem('timeout');

    localStorage.setItem("now", new Date().getTime());
    if(new Date().getTime()>(timeout-3600000))
    {

        let rqtoken = {
          "UserID": UserID
        }

        let tokenservice = await CallerRefreshTokenController(rqtoken);
        console.log("refreshtopen return", tokenservice.data);

        if(tokenservice.data!==undefined && tokenservice.data!==null && tokenservice.data!=="")
        {
          if(tokenservice.status !== 200)
          {
            localStorage.clear();
            console.log("refreshtopen return 2 clear");
            window.location.href = "/";
          }
        }
        else
        {
          localStorage.clear();
          console.log("refreshtopen return 3 clear");
          window.location.href = "/";
        }

        let token = tokenservice.data;
        localStorage.setItem('mypearl', token);

        const decoded = jwtDecode(token);
        let timeout=(decoded.exp * 1000);
        localStorage.setItem('myuid', decoded.nameid);
        localStorage.setItem('companyID', decoded.CompanyID);
        localStorage.setItem('companyName', decoded.CompanyName);
        localStorage.setItem("userName", decoded.UserName);
        localStorage.setItem("userEmail", decoded.Email);
        localStorage.setItem("timeout", timeout);

        //console.log("decoded", decoded);

        let luser ={user:{id: decoded.nameid, name: decoded.unique_name, image:"https://via.placeholder.com/150", uid:decoded.nameid}}

        localStorage.setItem('user',JSON.stringify(luser));

        return luser
      }

      return JSON.parse(localStorage.getItem('user'));
    /*
    await  auth.signInWithEmailAndPassword(email, password)
      .then(authUser => authUser)
      .catch(error => error);
      */
    }

const signOutRequest = async () =>
  await  auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);


const signInUserWithGoogleRequest = async () =>
  await  auth.signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await  auth.signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await  auth.signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await  auth.signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, payload);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage('Your request has been canceled.'));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailRefreshToken({payload}) {

  const {UserID} = payload;

  try {
    //debugger;
    const signInUser = yield call(signInUserWithEmailRefreshTokenRequest, UserID);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem('user_id', signInUser.user.uid);
      yield put(userSignInSuccess(signInUser.user.uid));
    }
  } catch (error) {
    debugger;
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    //debugger;
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem('user_id', signInUser.user.uid);
      yield put(userSignInSuccess(signInUser.user.uid));
    }
  } catch (error) {
    debugger;
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* refreshToken() {
  yield takeEvery(REFRESH_TOKEN, signInUserWithEmailRefreshToken);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(refreshToken),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser)]);
}
