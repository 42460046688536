import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
  Tooltip,
  Tabs,
  Modal,
  AutoComplete,
  Collapse,
  Radio,
  Col,
  Row,
  Card,
  Select,
  Space,
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Alert,
  Checkbox,
  message,
  Carousel
} from 'antd';
import Tracking from "../routes/shipment/tracking";

function AwwexLandingPageBodyMiddle() {
  const [form] = Form.useForm();
  const [isHoveredSubmit, setIsHoveredSubmit] = useState(false);
  const submitimageLinkStyle = {
    display: "block",
    width: "819px",
    height: "102px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredSubmit
      ? "url('https://www.awwex.com/assets/images/AssetReferansSorgu/images/AwwEx-Site-Giydirme2_05.png')"
      : "url('https://www.awwex.com/assets/images/AssetReferansSorgu/images/AwwEx-Site-Giydirme2_05_first.png')", // Hover durumuna göre resim değişir
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [externalPageRequest, setExternalPageRequest] = useState(false);

  async function getexternalPageRequest()
  {
    const values = await form.getFieldsValue();
    let epr = {
      assetreference:values.awwexreference
    }
    debugger;
    setExternalPageRequest(epr);
    showModal();

  }

  return (
    <Form form={form} component={false}>
    <div className="AppBodyMiddle">
      <table id="Table_01" width="1920" height="324" border="0" cellPadding="0" cellSpacing="0">
        <tr>
          <td colSpan="3">
            <img src="https://www.awwex.com/assets/images/AssetReferansSorgu/images/AwwEx-Site-Giydirme2_01.png" width="1920" height="57"
                 alt=""/>
          </td>
        </tr>
        <tr>
          <td rowSpan="2">
            <img src="https://www.awwex.com/assets/images/AssetReferansSorgu/images/AwwEx-Site-Giydirme2_02.png" width="960" height="207"
                 alt=""/>
          </td>
          <td>
            <Form.Item label="" name="awwexreference">
              <Input placeholder="Awwex Reference" style={{marginLeft:33, width: 785, height: 65 }} />
            </Form.Item>
          </td>
          <td rowSpan="3">
            <img src="https://www.awwex.com/assets/images/AssetReferansSorgu/images/AwwEx-Site-Giydirme2_04.png" width="141" height="267" alt=""/></td>
        </tr>
        <tr>
          <td>
            <a
              href="#"
              onClick={async ()=>await getexternalPageRequest()}
              style={submitimageLinkStyle}
              onMouseEnter={() => setIsHoveredSubmit(true)} // Hover durumu aktif
              onMouseLeave={() => setIsHoveredSubmit(false)} // Hover durumu devre dışı
            >
              {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
            </a>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <img src="https://www.awwex.com/assets/images/AssetReferansSorgu/images/AwwEx-Site-Giydirme2_06.png" width="1779" height="60" alt=""/>
          </td>
        </tr>
      </table>
    </div>
      <Modal title="Fiyat Hesaplama" width={1800} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Tracking externalPageRequest={externalPageRequest}></Tracking>
      </Modal>
    </Form>
  )
}

export default AwwexLandingPageBodyMiddle;
