import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/icons/logo.png";
import telephoneIcon from "../images/icons/telephone.png";
import mailIcon from "../images/icons/mail.png";
import locationIcon from "../images/icons/location.png";
import assetLogo from "../images/icons/asset-logo.png";
import iyizicoLogo from "../images/icons/iyizico.png";
import facebookIcon from "../images/icons/facebook.png";
import instagramIcon from "../images/icons/instagram.png";
import twitterIcon from "../images/icons/x.png";
import linkedinIcon from "../images/icons/linkedin.png";
import whatsappIcon from "../images/icons/whatsapp.png";

function AwwexLandingFooter(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <footer className="footer">
      <table>
        <tbody>
          <tr>
            <td>
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
            </td>
            <td>
              <Link to="/about" className="redirect-button">
                <h1>HAKKIMIZDA</h1>
              </Link>
            </td>
            <td>
              <h1>DESTEK</h1>
            </td>
          </tr>
          <tr>
            <td>
              <h3>
                <a href="tel:4445127" className="redirect-button">
                  <img src={telephoneIcon} alt="Telefon" className="icon" />
                  444 5 127
                </a>
              </h3>
            </td>
            <td>
              <a
                href="https://assetgli.com/worldwideexpress-anasayfa"
                className="redirect-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Asset Worldwide Express</h3>
              </a>
            </td>
            <td>
              <a
                href="https://youtube.com/playlist?list=PL7Xy1lJyS4OU7xuEs0jnzQ4d5i3u67buY&si=rUoi38G7Pj9bZWyk"
                className="redirect-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Kullanım Videomuzu İzleyin</h3>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <h3>
                <a href="mailto:info@awwex.com" className="redirect-button">
                  <img src={mailIcon} alt="Adres" className="icon" />
                  info@awwex.com
                </a>
              </h3>
            </td>
            <td>
              <Link to="/privileges" className="redirect-button">
                <h3>Ayrıcalıklar</h3>
              </Link>
            </td>
            <td>
              <Link to="/sss" className="redirect-button">
                <h3>Sıkça Sorulan Sorular</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <h3>
                <a
                  href="https://maps.app.goo.gl/nn3pWdMhodqJ3QPeA"
                  className="redirect-button"
                >
                  <img src={locationIcon} alt="Adres" className="icon" />
                  Flat Ofis Defterdar Mah. Otakçılar Cd.
                  <br />
                  No:100/101 Kat:2 Eyüpsultan/İstanbul
                </a>
              </h3>
            </td>
            <td>
              <a
                href="https://assetgli.com/"
                className="redirect-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Asset GLI</h3>
              </a>
            </td>
            <td>
            <Link to="/sss" className="redirect-button">
                <h3>Terimler</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <div className="logo">
                <img src={assetLogo} alt="Logo" />
              </div>
            </td>
            <td>
              <img
                className="iyizico-logo"
                src={iyizicoLogo}
                alt="İyizico Logo"
              />
            </td>
            <td>
              <div className="social-icons">
                <a
                  href="https://facebook.com/assetgli"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebookIcon} alt="Facebook" />
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://instagram.com/assetworldwideexpress"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagramIcon} alt="Instagram" />
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://twitter.com/AssetGli"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitterIcon} alt="X (Twitter)" />
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://linkedin.com/company/assetworldwideexpress/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedinIcon} alt="LinkedIn" />
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://api.whatsapp.com/send/?phone=905327333777&text=Merhaba%21&type=phone_number&app_absent=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsappIcon} alt="WhatsApp" />
                </a>
                &nbsp;&nbsp;
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </footer>
  );
}

export default AwwexLandingFooter;
