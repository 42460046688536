import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import configureStore, { history } from './appRedux/store';
import "./firebase/firebase";
import App from "./containers/App/index";
import PricingGet from "./routes/pricing/pricingget";
import Tracking from "./routes/shipment/tracking"

const store = configureStore(/* provide initial state if any */);

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/pricingseperated" component={PricingGet}/>
        <Route path="/trackingseperated" component={Tracking}/>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>        
  </Provider>;


export default NextApp;
