import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, message, Row, Col } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'; // Yenile ikonu için

const generateCaptcha = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return captcha;
};

const CaptchaModal = ({ isVisible, onClose, handleCaptchaVerify }) => {
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const [captchaKey, setCaptchaKey] = useState(Date.now());

  const refreshCaptcha = () => {
    setCaptchaText(generateCaptcha());
    setCaptchaKey(Date.now());
  };

  const handleVerify = () => {
    if (userInput === captchaText) {
      message.success('Doğrulama başarılı');
      handleCaptchaVerify(true);
      onClose();
    } else {
      message.error('Doğrulama hatalı');
      setUserInput('');
      refreshCaptcha();
    }
  };

  const drawCaptcha = (context) => {
    context.clearRect(0, 0, 200, 100);
    context.font = '30px Arial';

    for (let i = 0; i < captchaText.length; i++) {
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      context.fillStyle = randomColor;
      context.fillText(captchaText.charAt(i), 30 * i + 20, 50);
    }
  };

  useEffect(() => {
    refreshCaptcha();
  }, []);

  return (
    <Modal
      title="Resimdekini doğrulayın"
      open={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Row justify="center">
        <Col>
          <canvas
            id="captchaCanvas"
            width="200"
            height="100"
            ref={(canvas) => {
              if (canvas) {
                const context = canvas.getContext('2d');
                drawCaptcha(context);
              }
            }}
          />
        </Col>
      </Row>

      <Input
        placeholder="Resimdeki karakterleri girin"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        style={{ margin: '10px 0' }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button type="primary" onClick={handleVerify} style={{ flex: 1, marginRight: '10px' }}>
          Doğrula
        </Button>
        <Button
          type="link"
          onClick={refreshCaptcha}
          icon={<ReloadOutlined />}
          style={{ padding: 0 }}
        />
      </div>
    </Modal>
  );
};

export default CaptchaModal;
