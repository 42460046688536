import React from "react";
import {Redirect, Route, Switch, useParams } from "react-router-dom";

import asyncComponent from "util/asyncComponent";


function Params() {
  let { } = useParams();
}

const PickupApps = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/pickup`}/>

    <Route path={`${match.url}/flightlist`} element={Params} component={asyncComponent(() => import('./flightlist.js'))}/>
    <Route path={`${match.url}/flightcreate`} element={Params} component={asyncComponent(() => import('./flightcreate.js'))}/>
    <Route path={`${match.url}/baglist/:flightId`} element={Params} component={asyncComponent(() => import('./baglist.js'))}/>
    <Route path={`${match.url}/baglist`} element={Params} component={asyncComponent(() => import('./baglist.js'))}/>
    <Route path={`${match.url}/baglabel`} element={Params} component={asyncComponent(() => import('./baglabel.js'))}/>
    <Route path={`${match.url}/shipmentlist/:bagId`} element={Params} component={asyncComponent(() => import('./shipmentlist.js'))}/>
    <Route path={`${match.url}/referencerule`} element={Params} component={asyncComponent(() => import('./referencerule.js'))}/>
    <Route path={`${match.url}/dispatcher`} element={Params} component={asyncComponent(() => import('./dispatcher.js'))}/>



  </Switch>
);

export default PickupApps;
