import React from "react";
import {Route, Switch} from "react-router-dom";

import Components from "./components/index";
import CustomViews from "./customViews/index";
import Extensions from "./extensions/index";
import ExtraComponents from "./extraComponents/index";
import InBuiltApps from "./inBuiltApps/index";
import SocialApps from "./socialApps/index";
import Main from "./main/index";
import Documents from "./documents/index";
import definition from "./definitions/index"
import ShipmentApps from "./shipment";
import PricingApps from "./pricing";
import InvoicingApps from "./invoicing";
import AgentApps from "./agent";
import PickupApps from "./pickup";
import MarketplacesApps from "./marketplaces";

const App = ({match}) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route path={`${match.url}definitions`} component={definition}/>
            <Route path={`${match.url}shipment`} component={ShipmentApps}/>
            <Route path={`${match.url}pricing`} component={PricingApps}/>
            <Route path={`${match.url}invoicing`} component={InvoicingApps}/>
            <Route path={`${match.url}agent`} component={AgentApps}/>
            <Route path={`${match.url}pickup`} component={PickupApps}/>
            <Route path={`${match.url}marketplaces`} component={MarketplacesApps}/>

            <Route path={`${match.url}main`} component={Main}/>
            <Route path={`${match.url}components`} component={Components}/>
            <Route path={`${match.url}custom-views`} component={CustomViews}/>
            <Route path={`${match.url}extensions`} component={Extensions}/>
            <Route path={`${match.url}extra-components`} component={ExtraComponents}/>
            <Route path={`${match.url}in-built-apps`} component={InBuiltApps}/>
            <Route path={`${match.url}social-apps`} component={SocialApps}/>
            <Route path={`${match.url}documents`} component={Documents}/>
        </Switch>
    </div>
);

export default App;
