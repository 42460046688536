import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
  Tooltip,
  Tabs,
  Modal,
  AutoComplete,
  Collapse,
  Radio,
  Col,
  Row,
  Card,
  Select,
  Space,
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Alert,
  Checkbox,
  message,
  Carousel
} from 'antd';
import VideoPlayer from './VideoPlayer';
import VideoOverlay from "./VideoOverlayV2";
import PricingGet from "../routes/pricing/pricingget";
import {CallerGetCountryListController} from "../datalayer/definations/callerdefinationcontroller";
import Awwexlandingfooter from "./awwexlandingfooter";
function Awwexlangingpageindexayricaliklar(props) {
  const [form] = Form.useForm();
  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  const styles = {
    container: {
      position: "relative",
      width: "100%",
      height: "100%",
      margin: "0 auto",
    },
    overlayheader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "65%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // %50 siyah transparan
      display: "flex",
      justifyContent: "center",
      backgroundImage: "url('https://www.awwex.com/assets/images/ayricaliklar/images/awwexayricaliklar_01.png')", // Hover durumuna göre resim değişir
    },
    overlay: {
      position: "relative",
      top: 700,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // %50 siyah transparan
      display: "none",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      color: "#fff",
      textAlign: "center",
      padding: "20px",
    },
    title: {
      fontSize: "24px",
      marginBottom: "20px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    input: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      outline: "none",
      width: "200px",
    },
    button: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      backgroundColor: "#1677ff",
      color: "#fff",
      border: "none",
      cursor: "pointer",
    }
  };
  const [isHoveredSign, setIsHoveredSign] = useState(false);
  const [isHoveredRecord, setIsHoveredRecord] = useState(false);
  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredInstagram, setIsHoveredInstagram] = useState(false);
  const [isHoveredX, setIsHoveredX] = useState(false);
  const [isHoveredLinkedin, setIsHoveredLinkedin] = useState(false);
  const [isHoveredWhatsapp, setIsHoveredWhatsapp] = useState(false);
  const [isHoveredLanguage, setIsHoveredLanguage] = useState(false);
  // Temel stil objesi
  const recordimageLinkStyle = {
    display: "block",
    width: "225px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredRecord
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_05.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_05.png')", // Hover durumuna göre resim değişir
  };

  const signimageLinkStyle = {
    display: "block",
    width: "219px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredSign
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_06.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_06.png')", // Hover durumuna göre resim değişir
  };

  const facebookimageLinkStyle = {
    display: "block",
    width: "65px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredFacebook
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_07.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_07.png')", // Hover durumuna göre resim değişir
  };

  const instagramimageLinkStyle = {
    display: "block",
    width: "55px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredInstagram
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_08.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_08.png')", // Hover durumuna göre resim değişir
  };

  const ximageLinkStyle = {
    display: "block",
    width: "55px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredX
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_09.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_09.png')", // Hover durumuna göre resim değişir
  };

  const linkedinimageLinkStyle = {
    display: "block",
    width: "55px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredLinkedin
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_10.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_10.png')", // Hover durumuna göre resim değişir
  };

  const whatsappimageLinkStyle = {
    display: "block",
    width: "57px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredWhatsapp
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_11.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_11.png')", // Hover durumuna göre resim değişir
  };

  const languageimageLinkStyle = {
    display: "block",
    width: "70px",
    height: "74px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredLanguage
      ? "url('https://www.awwex.com/assets/images/landingheader2/header2_12.png')"
      : "url('https://www.awwex.com/assets/images/landingheader/header_12.png')", // Hover durumuna göre resim değişir
  };

  const [isHoveredPricingSubmit, setIsHoveredPricingSubmit] = useState(false);
  const pricingsubmitimageLinkStyle = {
    display: "block",
    width: "773px",
    height: "83px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredPricingSubmit
      ? "url('https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_32.png')"
      : "url('https://www.awwex.com/assets/images/pricingcalculate/AwwEx-Site-Giydirme3_32_first.png')", // Hover durumuna göre resim değişir
  };

  const { Option } = Select;
  const [countryData, setCountryData] = useState([]);
  const fetchCountries = async (value) => {
    let request = { SearchText: value };
    let response = await CallerGetCountryListController(request);
    if (response) {
      const options = response.map(country => ({
        key: country.id,
        value: country.code,
        label: country.name
      }));
      setCountryData(options);
    }
  };

  useEffect( async () => {
    await fetchCountries();
  },[]);

  const [isVisiblePricing, setIsVisiblePricing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [externalPageRequest, setExternalPageRequest] = useState(false);
  async function getexternalPageRequest()
  {
    const values = await form.getFieldsValue();
    let epr = {
      packagetype:values.pakettipi,
      posttype:values.gonderitipi,
      country:values.ulkeler,
      zipcode:values.postakodu
    }
    debugger;
    setExternalPageRequest(epr);
    showModal();

  }

  return (
    <div className="App" style={{backgroundColor:"black", margin:"0px auto"}}>
      <header className="App-header">

        {/* Siyah transparan katman */}
        <div style={styles.overlayheader}>
          <table id="Table_01" width="1920"  border="0" cellPadding="0" cellSpacing="0" style={{"marginTop": "-190px"}}>
            <tr>
              <td>
                <img src="https://www.awwex.com/assets/images/landingheader/header_02.png" width="81" height="74" alt=""/></td>
              <td>
                <a href={"https://www.awwex.com/"}>
                <img src="https://www.awwex.com/assets/images/landingheader/header_03.png" width="314" height="74" alt=""/></a></td>
              <td>
                <img src="https://www.awwex.com/assets/images/landingheader/header_04.png" width="621" height="74" alt=""/></td>
              <td>
                <a
                  href="https://www.awwex.com/signup"
                  style={recordimageLinkStyle}
                  onMouseEnter={() => setIsHoveredRecord(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredRecord(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <a
                  href="https://www.awwex.com/signin"
                  style={signimageLinkStyle}
                  onMouseEnter={() => setIsHoveredSign(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredSign(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <a
                  target={"_blank"}
                  href="https://www.facebook.com/assetgli/"
                  style={facebookimageLinkStyle}
                  onMouseEnter={() => setIsHoveredFacebook(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredFacebook(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <a
                  target={"_blank"}
                  href="https://www.instagram.com/assetworldwideexpress/"
                  style={instagramimageLinkStyle}
                  onMouseEnter={() => setIsHoveredInstagram(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredInstagram(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <a
                  target={"_blank"}
                  href="https://x.com/AssetGli"
                  style={ximageLinkStyle}
                  onMouseEnter={() => setIsHoveredX(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredX(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>


              <td>
                <a
                  target={"_blank"}
                  href="https://www.linkedin.com/company/assetworldwideexpress/posts/?feedView=all"
                  style={linkedinimageLinkStyle}
                  onMouseEnter={() => setIsHoveredLinkedin(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredLinkedin(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>

              <td>
                <a
                  target={"_blank"}
                  href="https://api.whatsapp.com/send/?phone=905327333777&text=Merhaba%21&type=phone_number&app_absent=0"
                  style={whatsappimageLinkStyle}
                  onMouseEnter={() => setIsHoveredWhatsapp(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredWhatsapp(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>

              <td>
                <a
                  href="https://www.awwex.com/"
                  style={languageimageLinkStyle}
                  onMouseEnter={() => setIsHoveredLanguage(true)} // Hover durumu aktif
                  onMouseLeave={() => setIsHoveredLanguage(false)} // Hover durumu devre dışı
                >
                  {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
                </a>
              </td>
              <td>
                <img src="https://www.awwex.com/assets/images/landingheader/header_13.png" width="103" height="74" alt=""/></td>
            </tr>
            <tr>
              <td colSpan="12">
                <img src="https://www.awwex.com/assets/images/landingheader/header_14.png" width="1920" height="16" alt=""/></td>
            </tr>
        </table>
        </div>
        <div style={{marginTop:"600px", height:"500px",position:"absolute", backgroundColor:"red"}}>
          <img src="https://www.awwex.com/assets/images/ayricaliklar/images/awwexayricaliklar_02.png" />
        </div>


</header>
  <body>

      </body>
      <footer>
        <div style={{"position": "absolute",marginTop:"10900px"}} >
          <Awwexlandingfooter masterFunction={props.masterFunction}></Awwexlandingfooter>
        </div>
      </footer>
    </div>
  )
    ;
}

export default Awwexlangingpageindexayricaliklar;
