function getUrl(){

  const isLocalhost = Boolean(
    window.location.hostname === 'www.awwex.com' ||
    // [::1] is the IPv6 www.awwex.com address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered www.awwex.com for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );

  console.log("isLocalhost", isLocalhost);

  return isLocalhost?"https://www.awwex.com":"https://www.awwex.com";
}


export {getUrl}
