import React, {useState, useEffect, useCallback} from "react";
import {Form, Card, Col, Input, Row, Steps, Table, message, Radio, Space, Button} from "antd";
import { GoPackage, GoPackageDependents } from "react-icons/go";
import { LiaShippingFastSolid } from "react-icons/lia";
import { LuPackageSearch, LuPackageCheck } from "react-icons/lu";
import { CallerPullShipmentTrackingStatusCommand } from "../../datalayer/tracking/callertrackingcontroller";
import {jwtDecode} from "jwt-decode";
import { useHistory } from "react-router-dom";

export default function Tracking(props) {
  const history = useHistory();
  const [form] = Form.useForm();
  const { Search } = Input;
  const [reference, setReference] = useState("");
  const [data, setData] = useState({
    shipmentTrackingStatusResults: {
      estimatedDeliveryTime: null,
      trackingReference: "",
      trackingInformations: [],
    },
    receiverCountryName: "",
  });
  const [selectedPieceTrackingReference, setSelectedPieceTrackingReference] = useState("");
  const [isCustomerUser, setIsCustomerUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const determineCurrentStep = useCallback((trackingInformations) => {
    if (!trackingInformations || trackingInformations.length === 0) return 0;

    const latestGroupId = trackingInformations[0].trackingStatusGroupId;

    if (!latestGroupId) return 0;

    return latestGroupId;
  }, []);

  useEffect(async () => {
    localStorage.setItem("pageName", "Gönderi Takibi");

    let externalPageRequest = {};
    try {
      externalPageRequest =
          props.location.pathname.split("/")[2] != "tracking"
              ? JSON.parse(props.location.pathname.split("/")[2])
              : null;
      if (externalPageRequest) {
        setReference(externalPageRequest.assetreference);
      } else {
        const tokenJWT = localStorage.getItem("mypearl");
        const decoded = jwtDecode(tokenJWT);
        setIsCustomerUser(decoded.role.includes("user4controllers"));
      }
    } catch (error) {
      console.error("externalPageRequest error", error);
    }
  }, []);

  useEffect( async () => {
    localStorage.setItem("pageName", "Gönderi Takibi");
    try {
      if (props.externalPageRequest){
        setReference(props.externalPageRequest.assetreference);
      }
    } catch (error) {
      console.log('externalPageRequest error',error)
    }
  },[props.externalPageRequest]);

  useEffect(() => {
    if (reference) {
      form.setFieldsValue({
        assetReference: reference
      });
      form.submit();
    }
  }, [reference]);

  useEffect(() => {
    if (data?.shipmentTrackingStatusResults?.[0]?.trackingInformations?.length > 0) {
      setSelectedPieceTrackingReference(data.shipmentTrackingStatusResults[0].trackingReference);
    }
  }, [data]);

  useEffect(() => {
    if (data?.shipmentTrackingStatusResults?.[0]?.trackingInformations?.length > 0) {
      const currentResult = data.shipmentTrackingStatusResults?.find(
        r => r.trackingReference === selectedPieceTrackingReference
      );
      if (!currentResult) return;
      const step = determineCurrentStep(currentResult.trackingInformations);
      setCurrentStep(step);
    }
  }, [data, selectedPieceTrackingReference, determineCurrentStep]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const userId = localStorage.getItem('user_id');
      if (userId) {
        values.userId = userId;
      }
      const response = await CallerPullShipmentTrackingStatusCommand(values);
      if (response?.status === 200 && response?.data?.shipmentTrackingStatusResults?.length > 0) {
        response.data.shipmentTrackingStatusResults.forEach((result, index) => {
          result.trackingInformations.forEach((trackingInformation, trackingIndex) => {
            trackingInformation.key = index + "-" + trackingIndex;
          });
        });
        setData(response.data);
      } else {
        setData([]);
        message.error(response.ReturnText ?? "Gönderi takip bilgisi bulunamadı");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTrackingReferenceRadioChange = useCallback((e) => {
    setSelectedPieceTrackingReference(e.target.value);
  }, []);

  const handleMatchStatus = (record) => {
    history.push(`/agent/trackingstatuslist/${record.trackingStatusIdToBeMatched}`);
  };

  const columns = [
    {
      title: "Tarih",
      dataIndex: "date",
      render: (value) => (value ? new Date(value).toLocaleString() : ""),
    },
    {
      title: "Konum",
      dataIndex: "location",
    },
    {
      title: "Durum",
      dataIndex: "description",
    },
    {
      title: "",
      key: "actions",
      render: (_, record) => {
        if (!isCustomerUser && record.isNeededToBeMatchedWithACustomizedTrackingStatus) {
          return (
            <Button type="primary" onClick={() => handleMatchStatus(record)}>
              Eşleştir
            </Button>
          );
        }
        return null;
      },
      hidden: isCustomerUser
    }
  ].filter(col => !col.hidden);

  return (
    <>
      <Card className="gx-card">
        <Row>
          <Form name="tracking" form={form} onFinish={onFinish}>
            <Col span={24}>
              <Form.Item name="assetReference" rules={[{ required: true, message: "Referans numarası giriniz" }]}>
                <Search
                  placeholder="Referans"
                  loading={loading}
                  onSearch={(value) => {
                    form.submit();
                  }}
                  enterButton
                  style={{ width: 300 }}
                />
              </Form.Item>
            </Col>
          </Form>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 20 }}>
                <strong style={{ fontWeight: "bold" }}>Alıcı Ülke:</strong>{" "}
                {data?.receiverCountryName}
              </div>
              {data?.shipmentTrackingStatusResults?.estimatedDeliveryTime !=
                null && (
                <div>
                  <strong style={{ fontWeight: "bold" }}>
                    Tahmini Teslim Tarihi:
                  </strong>{" "}
                  {new Date(
                    data?.shipmentTrackingStatusResults?.estimatedDeliveryTime
                  ).toLocaleString()}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 20, marginBottom: 20 }}>
          <Col span={24}>
            <Steps
              items={[
                {
                  title: "Gönderi Alındı",
                  status: currentStep == 1 ? "process" : currentStep > 1 ? "finish" : "wait",
                  icon: <GoPackage />,
                },
                {
                  title: "İşlemde",
                  status: currentStep == 2 ? "process" : currentStep > 2 ? "finish" : "wait",
                  icon: <LuPackageSearch />,
                },
                {
                  title: "Sevk Ediliyor",
                  status: currentStep == 3 ? "process" : currentStep > 3 ? "finish" : "wait",
                  icon: <LiaShippingFastSolid />,
                },
                {
                  title: "Dağıtımda",
                  status: currentStep == 4 ? "process" : currentStep > 4 ? "finish" : "wait",
                  icon: <GoPackageDependents />,
                },
                {
                  title: "Teslim Edildi",
                  status: currentStep == 5 ? "process" : currentStep > 5 ? "finish" : "wait",
                  icon: <LuPackageCheck />,
                },
              ]}
            />
          </Col>
        </Row>
        {data?.shipmentTrackingStatusResults && data.shipmentTrackingStatusResults.length > 1 &&
          <Row>
            <Col span={24}>
              {
                <>
                  <Radio.Group
                      value={selectedPieceTrackingReference}
                      style={{marginBottom: 20}}
                      onChange={handleTrackingReferenceRadioChange}
                  >
                    <Space>
                      {data.shipmentTrackingStatusResults.map((result, i) => (
                          <Radio
                              key={result.trackingReference}
                              value={result.trackingReference}
                          >
                            Parça {i + 1}: {result.trackingReference}
                          </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </>
              }
            </Col>
          </Row>
        }
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={Array.isArray(data?.shipmentTrackingStatusResults)
                  ? data.shipmentTrackingStatusResults
                      .filter(r => r.trackingReference === selectedPieceTrackingReference)
                      .flatMap(r => r.trackingInformations || [])
                  : []}
              rowKey="key"
              rowClassName={(record) => (isCustomerUser || !record.isNeededToBeMatchedWithACustomizedTrackingStatus) ? '': 'unmapped-row' }
              className="tracking-table"
            ></Table>
          </Col>
        </Row>
      </Card>
      <style>{`
        .tracking-table .unmapped-row {
          background-color: #ffecec;
        }
        .tracking-table .unmapped-row:hover > td {
          background-color: #ffdede !important;
        }
      `}</style>
    </>
  );
}
