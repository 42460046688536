
import axios from 'axios';
//import AxiosMockAdapter from 'axios-mock-adapter';

//const axiosInstance = axios.create();

const axiosInstance = axios.create({
  baseURL: 'https://www.awwex.com',
  timeout: 10000
});

/*
axiosInstance.interceptors.response.use((response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'));
*/

axiosInstance.interceptors.request.use(function (config) {

  const token = localStorage.getItem('mypearl');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;

});

axiosInstance.interceptors.response.use((response) => {
    return response  
}, async function (error) {
  
    const originalRequest = error.config;
  
    console.log("axiosInstance error", error.response);
    /*
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();            
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axiosInstance(originalRequest);
    }
    */
  
    return Promise.reject(error);
  
});

export default axiosInstance;