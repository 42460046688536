import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hideMessage, showAuthLoader, userSignIn } from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

const SignIn = () => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  }, [showMessage, authUser, dispatch, history]);

  const handleCaptchaVerify = (value) => {
    if (value) {
      setIsCaptchaVerified(true);
    } else {
      setIsCaptchaVerified(false);
    }
  };

  async function handleSignIn() {
    const values = await form.getFieldsValue();
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  }

  const onFinish = (values) => {
    if (!isCaptchaVerified) {
      message.error("Lütfen CAPTCHA'yı doğrulayın!");
    } else {
      handleSignIn();
    }
  };

  return (
    <>
      <Helmet>
        <title>Giriş Yap - Awwex </title>
      </Helmet>
      <div
        className="gx-app-login-wrap"
        style={{
          opacity: 0.8,
          backgroundImage:
            "url('https://www.awwex.com/assets/images/loginbackgrounds/awwexlogin.png')",
        }}
      >
        <div
          className="gx-app-login-container"
          style={{ boxShadow: "3px 3px 9px black" }}
        >
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={"/assets/images/back272x395.png"} alt="Neature" />
              </div>
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.signIn" />
                </h1>
                <p>
                  <IntlMessages id="app.userAuth.bySigning" />
                </p>
                <p>
                  <IntlMessages id="app.userAuth.getAccount" />
                </p>
              </div>
              <div className="gx-app-logo">
                <a href="https://www.awwex.com">
                  <img alt="example" src="/assets/images/logo-white.png" />
                </a>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                form={form}
                initialValues={{ remember: true }}
                name="basic"
                onFinish={onFinish}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  rules={[
                    { required: true, message: "E-Mail Adresi Giriniz!" },
                  ]}
                  name="email"
                >
                  <Input placeholder="E-Mail" />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Lütfen Parola Giriniz!" },
                  ]}
                  name="password"
                >
                  <Input type="password" placeholder="Parola" />
                </Form.Item>

                {/* Google reCAPTCHA */}
                <Form.Item>
                  <ReCAPTCHA
                    sitekey="6LexsbAqAAAAACacU2yXNU7_B5SSM7pLdavaJKeU"
                    onChange={handleCaptchaVerify}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    disabled={!isCaptchaVerified}
                  >
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                  <span>
                    <IntlMessages id="app.userAuth.or"/>
                  </span>{" "}
                  <Link to="/signup">
                    <IntlMessages id="app.userAuth.signUp"/>
                  </Link>
                  <span>
                    <br></br><br></br>
                    <IntlMessages id="app.userAuth.forgot"/>
                  </span>{" "}
                  <Link to="/signremember">
                    <IntlMessages id="app.userAuth.forgotPassword"/>
                  </Link>
                </Form.Item>
                <br></br>
                <span className="gx-text-light gx-fs-sm">
                  Destek talepleriniz için bize info@awwex.com adresi üzerinden
                  ulaşabilirsiniz.
                </span>
              </Form>
            </div>

            {loader ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {showMessage ? message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
