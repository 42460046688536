import React, { useRef } from "react";

const VideoPlayer = ({ src, poster, width = "100%",height="100%" }) => {
  const videoRef = useRef(null);

  const handlePlay = () => {
    videoRef.current?.play();
  };

  const handlePause = () => {
    videoRef.current?.pause();
  };

  return (
    <div>
      <video  autoPlay loop muted
              ref={videoRef}
        width={width}
        height={height}
        controls
        style={{ backgroundColor: "black" }}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={{ marginTop: 10 }}>
        <button onClick={handlePlay}>Play</button>
        <button onClick={handlePause}>Pause</button>
      </div>
    </div>
  );
};

export default VideoPlayer;
