import React from "react";
import {Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const InvoicingApps = ({match}) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/invoicing`}/>
        <Route path={`${match.url}/incomeinvoicing`} component={asyncComponent(() => import('./incomeinvoicing.js'))}/>
        <Route path={`${match.url}/expenseinvoicing`} component={asyncComponent(() => import('./expenseinvoicing.js'))}/>
    </Switch>
);

export default InvoicingApps;