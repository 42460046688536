import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../datalayer';
import getMyMessage from '../../util/RestReturnCatchForException';

async function CallerCreateUserRoleGroupmapController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/CreateUserRoleGroupmap', reqTempItem);
            return response.data;
        }
        catch (error) {
    
            debugger;
            let jsonRtrn = {}
            try {
                jsonRtrn = getMyMessage(error);
            } catch (error) {
                jsonRtrn.ReturnCode = 500;
                jsonRtrn.ReturnText = error.response;
            }
            return jsonRtrn;
        }
}

async function CallerGetUserRoleGroupmapsWithPaginationController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/GetUserRoleGroupmapsWithPaginationQuery', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

export {CallerCreateUserRoleGroupmapController, CallerGetUserRoleGroupmapsWithPaginationController}