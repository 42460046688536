import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../datalayer';

async function CallerGetCompanyListPaginationController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/Company/GetCompanyListPaginationQuery', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerGetCompanyAddressListPaginationController(reqTempItem){

  //debugger;
      try {
          const response = await postAPICall('/portalbend/Company/GetCompanyAddressListPaginationQuery', reqTempItem);
          return response.data;
      }
      catch(error){
          return error.response;
      }
}

async function CallerCreateOrUpdateCompanyController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/Company/CreateOrUpdateCompany', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerCreateOrUpdateAddressController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/Company/CreateOrUpdateAddress', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerCreateOrUpdateCompanyAddressController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/Company/CreateOrUpdateCompanyAddress', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerCreateOrUpdateCompanyEmployeesController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/Company/CreateOrUpdateCompanyEmployees', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}


export {CallerCreateOrUpdateCompanyEmployeesController, CallerCreateOrUpdateCompanyAddressController, CallerCreateOrUpdateAddressController, CallerCreateOrUpdateCompanyController, CallerGetCompanyAddressListPaginationController, CallerGetCompanyListPaginationController}
