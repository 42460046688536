import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../datalayer';

async function CallerCreateUserRoleGroupmapController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/CreateUserRoleGroupmap', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerGetUserRoleGroupmapsWithPaginationController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/GetUserRoleGroupmapsWithPaginationQuery', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

export {CallerCreateUserRoleGroupmapController, CallerGetUserRoleGroupmapsWithPaginationController}