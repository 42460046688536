import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../datalayer';

async function CallerCreateUserMenuController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/CreateUserMenu', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerGetUserMenuWithPaginationQueryController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/GetUserMenuWithPaginationQuery', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerGetUserMenuFromUserQueryController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/GetUserMenuFromUserQuery', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}



export {CallerCreateUserMenuController, CallerGetUserMenuWithPaginationQueryController,CallerGetUserMenuFromUserQueryController}