import React from "react";
import {Redirect, Route, Switch, useParams } from "react-router-dom";

import asyncComponent from "util/asyncComponent";


function Params() {
  let { userid, billofladingid } = useParams();
}

function CreateShipmentByMarketplaceAndMarketplaceOrderParams () {
  let { marketplaceId, marketplaceOrderId } = useParams();
}

const ShipmentApps = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/shipment`}/>

    <Route path={`${match.url}/shipment/marketplace/:marketplaceId/:marketplaceOrderId`} element={CreateShipmentByMarketplaceAndMarketplaceOrderParams} component={asyncComponent(() => import('./shipmentcreate.js'))}/>
    <Route path={`${match.url}/shipment/:userid/:billofladingid`} element={Params} component={asyncComponent(() => import('../shipment/shipmentcreate.js'))}/>
    <Route path={`${match.url}/shipment`} component={asyncComponent(() => import('./shipmentcreate.js'))}/>
    <Route path={`${match.url}/receiversmenu`} component={asyncComponent(() => import('./receivermenu.js'))}/>
    <Route path={`${match.url}/sendersmenu`} component={asyncComponent(() => import('./sendermenu.js'))}/>
    <Route path={`${match.url}/shipmentlist`} component={asyncComponent(() => import('./shipmentlistparent.js'))}/>
    <Route path={`${match.url}/tracking`} component={asyncComponent(() => import('./tracking.js'))}/>
    <Route path={`${match.url}/assetlabel/:billofladingid`} component={asyncComponent(() => import('./assetlabel.js'))}/>
    <Route path={`${match.url}/reservationcreate`} component={asyncComponent(() => import('./reservationcreate.js'))}/>
    <Route path={`${match.url}/reservationdetail/:reservationId/:reservationNumber`} element={Params} component={asyncComponent(() => import('./reservationdetail.js'))}/>

  </Switch>
);

export default ShipmentApps;
