import { postAPICall } from '../datalayer';

async function CallerGetAgencyListController(reqTempItem) {

    try {
        const response = await postAPICall('/portalbend/Defination/GetAgencyListQuery', reqTempItem);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
}

async function CallerGetAirlineListController(reqTempItem) {

    try {
        const response = await postAPICall('/portalbend/Defination/GetAirlineListQuery', reqTempItem);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
}

async function CallerGetAirportListController(reqTempItem) {

    try {
        const response = await postAPICall('/portalbend/Defination/GetAirportListQuery', reqTempItem);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
}

async function CallerGetCountryListController(reqTempItem) {

    try {
        const response = await postAPICall('/portalbend/Defination/GetCountryListQuery', reqTempItem);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
}

async function CallerGetSupplierListController(reqTempItem) {

    try {
        const response = await postAPICall('/portalbend/Defination/GetSupplierListQuery', reqTempItem);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
}

async function CallerGetProvinceListController(reqTempItem) {
    try {
        const response = await postAPICall('/portalbend/Defination/GetProvinceListQuery', reqTempItem);
        return response.data;
    } catch (error) {
        return error.response;
    }
}

async function CallerGetDistrictListController(reqTempItem) {
    try {
        const response = await postAPICall('/portalbend/Defination/GetDistrictListQuery', reqTempItem);
        return response.data;
    } catch (error) {
        return error.response;
    }
}

async function CallerGetNeighborhoodListController(reqTempItem) {
    try {
        const response = await postAPICall('/portalbend/Defination/GetNeighborhoodListQuery', reqTempItem);
        return response.data;
    } catch (error) {
        return error.response;
    }
}

async function CallerGetTownListController(reqTempItem) {
    try {
        const response = await postAPICall('/portalbend/Defination/GetTownListQuery', reqTempItem);
        return response.data;
    } catch (error) {
        return error.response;
    }
}

async function CallerGetLanguageTypesController() {
    try {
        const request = {};
        const response = await postAPICall('/portalbend/Defination/GetLanguageTypesQuery', request);
        return response.data;
    } catch (error) {
        return error.response;
    }
}

async function CallerGetCustomizedTrackingStatusesController(request) {
    try {
        const response = await postAPICall('/portalbend/Defination/GetCustomizedTrackingStatusesQuery', request);
        return response.data;
    } catch (error) {
        return error.response;
    }
}

async function CallerGetTrackingStatusGroupsController() {
    try {
        const request = {};
        const response = await postAPICall('/portalbend/Defination/GetTrackingStatusGroupsQuery', request);
        return response.data;
    } catch (error) {
        return error.response;
    }
}

async function CallerGetShipmentDocumentTypeController() {
    try {
        const request = {};
        const response = await postAPICall('/portalbend/Defination/GetShipmentDocumentTypeQuery', request);
        return response.data;
    } catch (error) {
        return error.response;
    }
}

async function CallerGetSegmentController() {
    try {
        const request = {};
        const response = await postAPICall('/portalbend/Defination/GetSegmentQuery', request);
        return response.data;
    } catch (error) {
        return error.response;
    }
}

export { 
    CallerGetAgencyListController ,
    CallerGetAirlineListController,
    CallerGetAirportListController,
    CallerGetCountryListController,
    CallerGetSupplierListController,
    CallerGetProvinceListController,
    CallerGetDistrictListController,
    CallerGetNeighborhoodListController,
    CallerGetTownListController,
    CallerGetLanguageTypesController,
    CallerGetCustomizedTrackingStatusesController,
    CallerGetTrackingStatusGroupsController,
    CallerGetShipmentDocumentTypeController,
    CallerGetSegmentController
}