import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";


const InBuiltApps = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/definitions`}/>
    <Route path={`${match.url}/usercrud`} component={asyncComponent(() => import('./usercrud/usercrud.js'))}/>
    <Route path={`${match.url}/rolecrud`} component={asyncComponent(() => import('./usercrud/rolecrud.js'))}/>
    <Route path={`${match.url}/rolegroupcrud`} component={asyncComponent(() => import('./usercrud/rolegroupcrud.js'))}/>
    <Route path={`${match.url}/rolerolegroupcrud`} component={asyncComponent(() => import('./usercrud/rolerolegroupcrud.js'))}/>
    <Route path={`${match.url}/usercrudsampletable`} component={asyncComponent(() => import('./usercrud/usercrudsampletable.js'))}/>
    <Route path={`${match.url}/usermenucrud`} component={asyncComponent(() => import('./usercrud/usermenucrud.js'))}/>
    <Route path={`${match.url}/userrolegroupcrud`} component={asyncComponent(() => import('./usercrud/userrolegroupcrud.js'))}/>

    <Route path={`${match.url}/paymentmethodcrud`} component={asyncComponent(() => import('./paymentmethodcrud/paymentmethodcrud.js'))}/>

    <Route path={`${match.url}/company`} component={asyncComponent(() => import('./companycrud/companycreate.js'))}/>
    <Route path={`${match.url}/shipment`} component={asyncComponent(() => import('../shipment/shipmentcreate.js'))}/>
    <Route path={`${match.url}/pricing`} component={asyncComponent(() => import('../pricing/pricingget.js'))}/>
    <Route path={`${match.url}/invoicing`} component={asyncComponent(() => import('../invoicing/incomeinvoicing.js'))}/>
    <Route path={`${match.url}/agent`} component={asyncComponent(() => import('../agent/documentreview.js'))}/>

  </Switch>
);

export default InBuiltApps;
