import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../datalayer';
import getMyMessage from '../../util/RestReturnCatchForException';

async function CallerCreateUserController(reqTempItem){

    //debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/CreateUser', reqTempItem);
            debugger;
            return response.data;
        }
        catch(error){

            debugger;
            let jsonRtrn = {}
            try {
                jsonRtrn = getMyMessage(error);
            } catch (error) {
                jsonRtrn.ReturnCode = 500;
                jsonRtrn.ReturnText = error.response;
            }

            return jsonRtrn;
        }
}

async function CallerUpdatePasswordController(reqTempItem) {
  try {
    debugger;

    const response = await postAPICall('/portalbend/UserCrud/UpdatePassword', reqTempItem);
    return response.data;
  } catch (error) {

    debugger;
    let jsonRtrn = {}
    try {
      jsonRtrn = getMyMessage(error);
    } catch (error) {
      jsonRtrn.ReturnCode = 500;
      jsonRtrn.ReturnText = error.response;
    }


    return jsonRtrn;
  }
}
async function CallerGetUserWithPaginationController(reqTempItem){

        try {
            const response = await postAPICall('/portalbend/UserCrud/GetUsersWithPaginationQuery', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerGetUserByRoleController(reqTempItem){

    debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/GetUsersByRole', reqTempItem);
            return response.data;
        }
        catch(error){
            return error.response;
        }
}

async function CallerGetUserInfoController(reqTempItem){

  //debugger;
  try {
    const response = await postAPICall('/portalbend/UserCrud/GetUserInfo', reqTempItem);
    return response.data;
  }
  catch(error){
    return error.response;
  }
}

async function CallerSendEmailForEmailConfirmationController(reqTempItem){

    debugger;
        try {
            const response = await postAPICall('/portalbend/UserCrud/SendEmailForEmailConfirmation', reqTempItem);
            return response.data;
        }
        catch(error){
            debugger;
            let jsonRtrn = {}
            try {
                jsonRtrn = getMyMessage(error);
            } catch (error) {
                jsonRtrn.ReturnCode = 500;
                jsonRtrn.ReturnText = error.response;
            }


            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            //console.log(error.toJSON());
            return jsonRtrn;
        }
}

export {CallerUpdatePasswordController, CallerGetUserInfoController, CallerCreateUserController, CallerGetUserWithPaginationController, CallerSendEmailForEmailConfirmationController,CallerGetUserByRoleController}
