import React, { useState, useEffect } from "react";
import { Select, Button, Checkbox, Form, Input, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
import { CallerSendEmailForEmailConfirmationController } from "../datalayer/usercrud/callerusercontroller";
import CaptchaModal from "./captcha";
import ReCAPTCHA from "react-google-recaptcha";

const FormItem = Form.Item;

const YouAreWelcomeSign = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [contentMsg, setContentMsg] = useState({});

  const WelcomeMessageForSendService =  () => {
    const destekWhatsappNumara = "+90 444 5 127";
    const destekEmailAdres = "destek@awwex.com";
    const konu = "AWWEX Kurum üyeliğiniz Onaylandı - Hoşgeldiniz";
    const kullaniciAdi=""
    try {

      fetch("https://www.awwex.com/youarewelcome.html")
        .then((response) => response.text())
        .then((data) => {
          const filledHtml = data
            .replace("[Kullanıcı Adı]", kullaniciAdi)
            .replace("[Whatsapp numarası]", destekWhatsappNumara)
            .replace("[Maili]", destekEmailAdres);
          console.log('filledHtml', filledHtml);
          setContentMsg(filledHtml);
        })
        .catch((error) => console.error("Error loading HTML file:", error));

    } catch (error) {
      console.error("Error in fetching and filling template:", error);
    }
  };

  useEffect(() => {
     WelcomeMessageForSendService();
  },[])

  return (
    <>
      <Helmet>
        <title> Awwex - Kayıt Tamamlandı </title>
      </Helmet>

      <div
        className="gx-app-login-wrap"
        style={{
          opacity: 0.8,
          backgroundImage:
            "url('https://www.awwex.com/assets/images/loginbackgrounds/awwexlogin.png')",
        }}
      >
        <div
          className="gx-app-login-container"
          style={{ boxShadow: "3px 3px 9px black" }}
        >
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content">
              <div style={{width:"700px"}}>
                {contentMsg && <div dangerouslySetInnerHTML={{ __html: contentMsg }} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YouAreWelcomeSign;
