import React, { useRef, useState, useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Divider, Radio, Col, Row,Card, Select, Space, Button, Form, Input, InputNumber, Popconfirm, Table, Typography  } from 'antd';


function DynamicFieldForPiece(props) {
  const [pieceCreateCount, setPieceCreateCount] = useState(0);
  const btnaddref = useRef(0);

  useEffect(() => {
    console.log("btnaddref.current", btnaddref.current);
    if(btnaddref.current && pieceCreateCount===0)
    {
      let pCount = pieceCreateCount+1;
      setPieceCreateCount(pCount);
      btnaddref.current.click();
    }
  },[btnaddref.current]);

  return (
    <Form.List name="fields">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Divider>Paket {index + 1}</Divider>

                <Row>
                    <Col style={{width:300}}>
                        <Form.Item name={[index, "PieceCount"]} label="Paket Adedi" rules={[{required: true}]}>
                            <InputNumber onChange={()=>props.changeFormData()}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item>
                            <span><nobr>ExBxY :</nobr></span>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name={[index, "PieceWidth"]} rules={[{required: true}]}>
                            <InputNumber onChange={()=>props.changeFormData()}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name={[index, "PieceLength"]} rules={[{required: true}]}>
                            <InputNumber onChange={()=>props.changeFormData()}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name={[index, "PieceHeight"]} rules={[{required: true}]}>
                            <InputNumber onChange={()=>props.changeFormData()}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item>
                            <span><nobr>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hacimsel Ağırlık :</nobr></span>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name={[index, "PieceVolumeWeight"]} rules={[{required: true}]}>
                            <InputNumber  onChange={()=>props.changeFormData()}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item>
                            <span><nobr>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ağırlık :</nobr></span>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name={[index, "PieceWeight"]} rules={[{required: true}]}>
                            <InputNumber onChange={()=>props.changeFormData()}></InputNumber>
                        </Form.Item>
                    </Col>

                </Row>

                {fields.length > 1 ? (
                  <Button
                    type="danger"
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Yukarıdaki ürünü sil
                  </Button>
                ) : null}
              </div>
            ))}
            <Divider />
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                ref={btnaddref}
              >
                <PlusOutlined /> Parça Ekle
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}



export default DynamicFieldForPiece;


