
function getMyMessage(exception)
{

    const regex = /"ReturnCode":(\d+),"ReturnText":"([^"]+)"/gm;
    let m;
    let jsonRtrn={}
    while ((m = regex.exec(exception.response.data)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        
        m.forEach((match, groupIndex) => {
            //console.log(`Found match, group ${groupIndex}: ${match}`);
            try {
                jsonRtrn=JSON.parse('{'+match+'}')
            } catch (error) {
                
            }
        });
    }

    return jsonRtrn;
}



export default getMyMessage;