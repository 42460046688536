import React, {useRef, useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {
  Tooltip,
  Tabs,
  Modal,
  AutoComplete,
  Collapse,
  Radio,
  Col,
  Row,
  Card,
  Select,
  Space,
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Alert,
  Checkbox,
  message,
  Carousel
} from 'antd';

function AwwexLandingPageBodyHumans(props) {

  //3,5,7,9,10,11
  const [isHoveredhuman3, setIsHoveredhuman3] = useState(false);
  const human3imageLinkStyle = {
    display: "block",
    width: "618px",
    height: "444px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredhuman3
      ? "url('https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_03.png')"
      : "url('https://www.awwex.com/assets/images/landinghumans2/images/humans_03.png')", // Hover durumuna göre resim değişir
  };
  const [isHoveredhuman5, setIsHoveredhuman5] = useState(false);
  const human5imageLinkStyle = {
    display: "block",
    width: "733px",
    height: "444px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredhuman5
      ? "url('https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_05.png')"
      : "url('https://www.awwex.com/assets/images/landinghumans2/images/humans_05.png')", // Hover durumuna göre resim değişir
  };
  const [isHoveredhuman7, setIsHoveredhuman7] = useState(false);
  const human7imageLinkStyle = {
    display: "block",
    width: "614px",
    height: "521px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredhuman7
      ? "url('https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_07.png')"
      : "url('https://www.awwex.com/assets/images/landinghumans2/images/humans_07.png')", // Hover durumuna göre resim değişir
  };
  const [isHoveredhuman9, setIsHoveredhuman9] = useState(false);
  const human9imageLinkStyle = {
    display: "block",
    width: "733px",
    height: "521px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredhuman9
      ? "url('https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_09.png')"
      : "url('https://www.awwex.com/assets/images/landinghumans2/images/humans_09.png')", // Hover durumuna göre resim değişir
  };
  const [isHoveredhuman10, setIsHoveredhuman10] = useState(false);
  const human10imageLinkStyle = {
    display: "block",
    width: "618px",
    height: "582px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredhuman10
      ? "url('https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_10.png')"
      : "url('https://www.awwex.com/assets/images/landinghumans2/images/humans_10.png')", // Hover durumuna göre resim değişir
  };
  const [isHoveredhuman11, setIsHoveredhuman11] = useState(false);
  const human11imageLinkStyle = {
    display: "block",
    width: "733px",
    height: "582px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textDecoration: "none",
    transition: "background-image 0.3s ease", // Yumuşak geçiş
    backgroundImage: isHoveredhuman11
      ? "url('https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_11.png')"
      : "url('https://www.awwex.com/assets/images/landinghumans2/images/humans_11.png')", // Hover durumuna göre resim değişir
  };

  return (
    <Form component={false}>
      <div className="AppBodyHumans">
        <table id="Table_01" width="1920" height="1629" border="0" cellPadding="0" cellSpacing="0">
          <tr>
            <td colSpan="6">
              <img src="https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_01.png" width="1920" height="82" alt=""/></td>
          </tr>
          <tr>
            <td rowSpan="3">
              <img src="https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_02.png" width="202" height="1547" alt=""/></td>
            <td colSpan="2">
              <a
                href="#"
                onClick={()=>props.masterFunction("privileges")}
                style={human3imageLinkStyle}
                onMouseEnter={() => setIsHoveredhuman3(true)} // Hover durumu aktif
                onMouseLeave={() => setIsHoveredhuman3(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td rowSpan="3">
              <img src="https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_04.png" width="235" height="1547" alt=""/></td>
            <td>
              <a
                href="#"
                onClick={()=>props.masterFunction("privileges")}
                style={human5imageLinkStyle}
                onMouseEnter={() => setIsHoveredhuman5(true)} // Hover durumu aktif
                onMouseLeave={() => setIsHoveredhuman5(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td rowSpan="3">
              <img src="https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_06.png" width="132" height="1547" alt=""/></td>
          </tr>
          <tr>
            <td>
              <a
                href="#"
                onClick={()=>props.masterFunction("privileges")}
                style={human7imageLinkStyle}
                onMouseEnter={() => setIsHoveredhuman7(true)} // Hover durumu aktif
                onMouseLeave={() => setIsHoveredhuman7(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td>
              <img src="https://www.awwex.com/assets/images/landinghumans/images/AwwEx-Site-Giydirme2_body_08.png" width="4" height="521" alt=""/></td>
            <td>
              <a
                href="#"
                onClick={()=>props.masterFunction("privileges")}
                style={human9imageLinkStyle}
                onMouseEnter={() => setIsHoveredhuman9(true)} // Hover durumu aktif
                onMouseLeave={() => setIsHoveredhuman9(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <a
                href="#"
                onClick={()=>props.masterFunction("privileges")}
                style={human10imageLinkStyle}
                onMouseEnter={() => setIsHoveredhuman10(true)} // Hover durumu aktif
                onMouseLeave={() => setIsHoveredhuman10(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
            <td>
              <a
                href="#"
                onClick={()=>props.masterFunction("privileges")}
                style={human11imageLinkStyle}
                onMouseEnter={() => setIsHoveredhuman11(true)} // Hover durumu aktif
                onMouseLeave={() => setIsHoveredhuman11(false)} // Hover durumu devre dışı
              >
                {/* Bu bağlantının üzerine gelindiğinde resim değişir */}
              </a>
            </td>
          </tr>
        </table>
      </div>
    </Form>
  )
}

export default AwwexLandingPageBodyHumans;
