import React, { useState, useEffect } from 'react';
import {  Select,Button, Checkbox, Form, Input, Modal} from "antd";
import {Link, useHistory} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
import {CallerSendEmailForEmailConfirmationController} from '../datalayer/usercrud/callerusercontroller';

const FormItem = Form.Item;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);
  const { Option } = Select;
  const [authDisplay, setAuthDisplay] = useState(false);
  const [authIsOk, setAuthIsOk] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sendbtnDisable, setSendbtnDisable] = useState(true);
  const [htmlContent, setHtmlContent] = useState("");
  const [htmlTitle, setHtmlTitle] = useState("");
  
  /*onChangeKvkk
onChangeGizlilik
onChangeTuketiciHaklari
*/
  const [checkKvkk, setCheckKvkk] = useState(false);
  const [checkGizlilik, setCheckGizlilik] = useState(false);
  const [checkTuketiciHaklari, setCheckTuketiciHaklari] = useState(false);
  
  const showModal = (htmlfileinfo) => {
    readHtml(htmlfileinfo)    
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (e) => {
    console.log(`Checked: ${e.target.checked}`);
    setSendbtnDisable(e.target.checked);
  };
  const onChangeKvkk = (e) => {
    //alert('test');
    console.log(`Checked: ${e.target.checked}`);
    setCheckKvkk(e.target.checked);
  };
  const onChangeGizlilik = (e) => {
    console.log(`Checked: ${e.target.checked}`);
    setCheckGizlilik(e.target.checked);
  };
  const onChangeTuketiciHaklari = (e) => {
    console.log(`Checked: ${e.target.checked}`);
    setCheckTuketiciHaklari(e.target.checked);
  };  

  useEffect(() => {
    //alert(checkKvkk);

    if(checkKvkk && checkGizlilik && checkTuketiciHaklari)
    {
      setSendbtnDisable(false);
    }
    else
    {
      //alert('true');
      setSendbtnDisable(true);
    }
  },[checkKvkk, checkGizlilik, checkTuketiciHaklari]);  

  const authControl = async (values) => {
    console.log('Email Success:', values);
  
    let req = {
      EmailProcess: 2,
      EmailAddress: values.email,
      ConfirmationCode:values.authCode
    }
  
    //row send
    let callerSendEmailForEmailConfirmationController = await CallerSendEmailForEmailConfirmationController(req);
    console.log("callerSendEmailForEmailConfirmationController", callerSendEmailForEmailConfirmationController);
  
    let convertCharacter = callerSendEmailForEmailConfirmationController.replaceAll("'", "\"")
    let resultJson = JSON.parse(convertCharacter);
    console.log("save resultJson", resultJson);
    if(resultJson.success==='True' || resultJson.success===true)//confirm email - record 
    {
      dispatch(showAuthLoader());
      dispatch(userSignUp(values));
      setAuthIsOk(true);

      /*
      setInterval(() => {
        console.log('control user')
        if(localStorage.getItem('user')!==undefined && localStorage.getItem('user')!=='')
        {
          window.location.replace('https://www.awwex.com/');
        }
      }, 5000); 
      */
    }
  };
const sendEmail = async(values) => {

    let req = {
      EmailProcess: 1,
      EmailAddress: values.email,
      ConfirmationCode:""
  }
  let callerSendEmailForEmailConfirmationController = await CallerSendEmailForEmailConfirmationController(req);
  console.log("callerSendEmailForEmailConfirmationController", callerSendEmailForEmailConfirmationController);
  debugger;
  if(callerSendEmailForEmailConfirmationController.ReturnCode)
  {
    message.error(callerSendEmailForEmailConfirmationController.ReturnText);
  }
  else
  {
    setAuthDisplay(true);
  }
};

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {

    if(authDisplay && !authIsOk)
    {
      authControl(values);
    }
    else if(!authIsOk){
      sendEmail(values);
    }

  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="90">+90</Option>
      </Select>
    </Form.Item>
  );

  function readHtml(htmlfileinfo)
  {

    let htmlfile="";
    switch (htmlfileinfo) {
      case "kvkk":
        setHtmlTitle("KVKK Metni");
        htmlfile="kvkk.html"
        break;
      case "gizlilikveguvenlik":
        setHtmlTitle("Gizlilik Ve Güvenlik Metni");
        htmlfile="gizlilikveguvenlik.html"
        break;
      case "tuketicihaklaricayma":
        setHtmlTitle("Tüketici Hakları Ve Cayma İptal Hakkı Metni");
        htmlfile="tuketicihaklaricaymaiptal.html"
        break;
  
        
      
      default:
        break;
    }

    fetch('https://www.awwex.com/'+htmlfile)
    .then(response => response.text())
    .then(data => {
      setHtmlContent(data);
      setIsModalVisible(true);
    })
    .catch(error => console.error('Error loading HTML file:', error));
  }

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signUp"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png"/>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{remember: true}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <FormItem rules={[{required: true, message: 'Please input your name!'}]} name="name">
                <Input placeholder="Name"/>
              </FormItem>
              <FormItem rules={[{required: true, message: 'Please input your username!'}]} name="lastname">
                <Input placeholder="Lastname"/>
              </FormItem>              

              <FormItem name="email" rules={[{
                required: true, type: 'email', message: 'The input is not valid E-mail!',
              }]}>
                <Input placeholder="Email"/>
              </FormItem>

              <Form.Item
                name="phone"
                rules={[{ required: false, message: 'Please input your phone number!' }]}
              >
                <Input placeholder="Phone" addonBefore={prefixSelector} />
              </Form.Item> 

              <FormItem name="password"
                        rules={[{required: true, message: 'Please input your Password!'}]}>
                <Input type="password" placeholder="Password"/>
              </FormItem>
              {/*
              <FormItem name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
                <Link className="gx-login-form-forgot" to="/custom-views/user-auth/forgot-password">Forgot
                  password</Link>
              </FormItem>
          */}
              {authDisplay &&
              <FormItem name="authCode" rules={[{
                required: true, message: 'Please input your code',
              }]}>
                <Input placeholder="Auth-Code"/>
              </FormItem>
              }

              <div style={{textAlign:"left"}}>
                  <Checkbox onChange={onChangeKvkk}>                    
                  KVKK Metni'ni okudum ve kabul ediyorum.{' '}                  
                  </Checkbox>
                  <Button style={{marginLeft:"8px"}} type="link" onClick={()=>showModal("kvkk")}>Detaylar için tıklayın</Button>                  
                  <br></br>
                  <Checkbox onChange={onChangeGizlilik}>                    
                  Gizlilik Ve Güvenlik Metni'ni okudum ve kabul ediyorum.{' '}
                  </Checkbox>                  
                  <Button style={{marginLeft:"8px"}} type="link" onClick={()=>showModal("gizlilikveguvenlik")}>
                    Detaylar için tıklayın</Button>                  
                  <br></br>
                  <Checkbox onChange={onChangeTuketiciHaklari}>                    
                  Tüketici Hakları - Cayma – İptal İade Koşulları Metni'ni okudum ve kabul ediyorum.{' '}
                  </Checkbox>                  
                  <Button style={{marginLeft:"8px"}} type="link" onClick={()=>showModal("tuketicihaklaricayma")}>
                    Detaylar için tıklayın</Button>                  
                  <Modal
                    title={htmlTitle}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                      <Button key="ok" type="primary" onClick={handleOk}>
                        Tamam
                      </Button>
                    ]}
                    style={{ maxWidth: '790px', width: '790px' }}
                    width={1000}
                  >
                      <div  dangerouslySetInnerHTML={{__html:htmlContent}} ></div>
                  </Modal>
                </div>
                  <br></br>
              <FormItem>
                <Button disabled={sendbtnDisable} type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signUp"/>
                </Button>
                <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                id="app.userAuth.signIn"/></Link>
              </FormItem>

              {/* 
              <div className="gx-flex-row gx-justify-content-between" >
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                    <GoogleOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGoogleSignIn());
                    }}/>
                  </li>
                  <li>
                    <FacebookOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userFacebookSignIn());
                    }}/>
                  </li>
                  <li>
                    <GithubOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGithubSignIn());
                    }}/>
                  </li>
                  <li>
                    <TwitterOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userTwitterSignIn());
                    }}/>
                  </li>
                </ul>
              </div>
              */}
            </Form>
          </div>
          {loader &&
          <div className="gx-loader-view">
            <CircularProgress/>
          </div>
          }
          {showMessage &&
          message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};


export default SignUp;
