import React from "react";

const VideoOverlay = ({ src }) => {
  return (
    <div style={styles.container}>
      {/* Video oynatıcı */}
      <video autoPlay loop muted style={styles.video}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Siyah transparan katman */}
      <div style={styles.overlay}>
        {/* Üst içerik */}
        <div style={styles.content}>
          <h2 style={styles.title}>Login</h2>
          <form style={styles.form}>
            <input
              type="text"
              placeholder="Username"
              style={styles.input}
            />
            <input
              type="password"
              placeholder="Password"
              style={styles.input}
            />
            <button style={styles.button}>Submit</button>
          </form>
        </div>
      </div>
      <div style={styles.overlayheader}>
        <table id="Table_01" width="1920" height="105" border="0" cellPadding="0" cellSpacing="0" style={{"marginTop": "70px"}} >
          <tr>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_02.png" width="81" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_03.png" width="314" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_04.png" width="621" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_05.png" width="225" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_06.png" width="219" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_07.png" width="65" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_08.png" width="55" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_09.png" width="55" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_10.png" width="55" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_11.png" width="57" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_12.png" width="70" height="74" alt=""/></td>
            <td>
              <img src="https://www.awwex.com/assets/images/landingheader/header_13.png" width="103" height="74" alt=""/></td>
          </tr>
          <tr>
            <td colSpan="12">
              <img src="https://www.awwex.com/assets/images/landingheader/header_14.png" width="1920" height="16" alt=""/></td>
          </tr>
        </table>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: "relative",
    width: "100%",
    margin: "0 auto",
  },
  video: {
    width: "100%",
    height: "100%",
    display: "block",
  },
  overlayheader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // %50 siyah transparan
    display: "flex",
    justifyContent: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // %50 siyah transparan
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    color: "#fff",
    textAlign: "center",
    padding: "20px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    outline: "none",
    width: "200px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    backgroundColor: "#1677ff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  },
};

export default VideoOverlay;
