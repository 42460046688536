import antdTR from "antd/lib/locale-provider/tr_TR";
import enMessages from "../locales/tr_TR.json";

const TRLang = {
  messages: {
    ...enMessages
  },
  antd: antdTR,
  locale: 'tr-TR',
};
export default TRLang;
